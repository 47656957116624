import React, { useState } from "react"
import { CardBody, Card } from "reactstrap"
import { toast } from "react-hot-toast"

import { DataTable } from "components/DataTable"
import PencilIcon from "assets/images/warehouse/inventory-management/pencil-icon.png"
import DeleteIcon from "assets/images/inventory/delete-icon.png"
import { SearchBox } from "components/SearchBox"
import { TableHeaderButtons } from "components/TableHeaderButtons"
import { PageLimit } from "components/PageLimit"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import {
  AddItemCategory,
  ItemCategoriesListing,
  UpdateItemCategory,
} from "api/api.service"
import AddCategoryForm from "./AddCategoryForm"

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const COLUMNS = {
  SERIAL_NO: "S. No.",
  CATEGORY: "Category",
  ACTION: "Actions",
}

const ItemCategoryListing = ({}) => {
  const [id, setId] = useState("")
  const [itemCategory, setItemCategory] = useState("")
  const [modalToggle, setModalToggle] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {
    data,
    total,
    page,
    searchText,
    pageSize,
    isFetching,
    handleSearchChange,
    handlePageClick,
    onChangePageSize,
    request,
  } = useLocalPaginatedRequest({
    requestFn: ItemCategoriesListing,
    params: {},
    deps: [],
    dataKey: DATA_KEY,
    totalKey: TOTAL_KEY,
  })

  const headerButtonsData = [
    {
      title: "Add New Category",
      onClick: () => setModalToggle(!modalToggle),
      classesButton: "ms-0 ",
    },
  ]

  const handleCloseModal = () => {
    setModalToggle(!modalToggle)
    setItemCategory("")
    setId("")
    setIsUpdate(false)
  }

  const handleSetItemsCategory = evt => {
    let value = evt.target.value
    setItemCategory(value)
  }

  const handleEditItemCategory = data => {
    setItemCategory(data.name)
    setId(data.id)
    setModalToggle(!modalToggle)
    setIsUpdate(!isUpdate)
  }

  const handleSubmit = async () => {
    let payload = {
      name: itemCategory,
    }

    if (isUpdate) {
      await handleUpdateItemCategory(payload)
    } else {
      await handleAddItemCategory(payload)
    }
    request()
  }

  const handleAddItemCategory = async payload => {
    try {
      setIsLoading(true)
      let res = await AddItemCategory(payload)
      setModalToggle(!modalToggle)
      setItemCategory("")
      toast.success("Item category has been added !")
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdateItemCategory = async payload => {
    try {
      setIsLoading(true)
      let res = await UpdateItemCategory(id, payload)
      setModalToggle(!modalToggle)
      setIsUpdate(!isUpdate)
      setItemCategory("")
      setId("")
      toast.success("Item category has been updated !")
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Card className="mb-5">
        <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
          <div className="d-flex align-items-center justify-content-end mb-4 mt-2">
            <div className="d-flex  mt-lg-0 me-0  wh-im-header-inner-btns-con ">
              <TableHeaderButtons
                data={headerButtonsData}
                classes={
                  "gt-btn-grad-primary px-2 px-md-4 wh-im-header-inner-btns btn-alignment"
                }
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between  ">
            <PageLimit
              pageSize={pageSize}
              changePageSizeHandler={onChangePageSize}
              rowLimit={pageSize}
              typoClasses="page-limit-typo"
              selectClasses="page-limit-select"
            />
            <div>
              <SearchBox
                searchText={searchText}
                onChange={handleSearchChange}
                inputClasses="table-main-search-input wh-im-search-input"
              />
            </div>
          </div>
          <DataTable
            data={data}
            loading={isFetching}
            config={[
              {
                title: COLUMNS.SERIAL_NO,
                render: data => {
                  return <>{data?.id}</>
                },
              },
              {
                title: COLUMNS.CATEGORY,
                render: data => {
                  return data?.name
                },
              },

              {
                title: COLUMNS.ACTION,
                render: data => (
                  <>
                    <span
                      className="text-success pointer mx-1 mx-md-3"
                      onClick={() => handleEditItemCategory(data)}
                    >
                      <img src={PencilIcon} />
                    </span>
                    {/* 
                    <span className="text-success pointer">
                      <img src={DeleteIcon} />
                    </span> 
                    */}
                  </>
                ),
              },
            ]}
          />
          <Pagination
            currentPage={page}
            totalData={total}
            onPageClick={handlePageClick}
            rowLimit={pageSize}
          />
        </CardBody>
      </Card>

      <Modal
        modalTitle={isUpdate ? "Edit Category" : "Add Category"}
        isOpen={modalToggle}
        handleModalToggling={handleCloseModal}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        size="md"
      >
        <AddCategoryForm
          onSubmit={handleSubmit}
          onChange={handleSetItemsCategory}
          value={itemCategory}
          isLoading={isLoading}
          isUpdate={isUpdate}
        />
      </Modal>
    </>
  )
}

export default ItemCategoryListing

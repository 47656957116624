import { injectBaseConstantMethods } from "./BaseConstants"

const RequestStatus = {
  OPEN: "Open",
  IN_PROGRESS: "InProgress",
  COMPLETED: "Completed",
}

const displayTextKeys = {
  [RequestStatus.OPEN]: "Open",
  [RequestStatus.IN_PROGRESS]: "In Progress",
  [RequestStatus.COMPLETED]: "Completed",
}

const labelClass = {
  [RequestStatus.OPEN]: "bg-red",
  [RequestStatus.IN_PROGRESS]: "bg-orange",
  [RequestStatus.COMPLETED]: "bg-green",
}

export default injectBaseConstantMethods(
  RequestStatus,
  displayTextKeys,
  labelClass
)

// Non auth public routes
import {
  Login,
  ForgetPassword,
  ForgetVerifyCode,
  ResetPassword,
} from "../pages/Authentication"
// Department
import { Dashboard as DeparmentDashboard } from "pages/DepartmentApp/Dashboard"
import { InventoryRequestListing as DeparmentInventoryRequest } from "pages/DepartmentApp/InventoryRequests"
import { Inventory as DeparmentInventory } from "pages/DepartmentApp/Inventory"
import { ScrapReturnListing as DeparmentScrapReturn } from "pages/DepartmentApp/ScrapReturn"
// Warehouse
import { Dashboard as WarehouseDashboard } from "pages/WarehouseApp/Dashboard"
import { InventoryRequest as WarehouseInventoryRequest } from "pages/WarehouseApp/InventoryRequests"
import { OrderManagementListing as WarehouseOrderManagement } from "pages/WarehouseApp/OrderManagement"
import { InventoryManagement as WarehouseInventoryManagement } from "pages/WarehouseApp/InventoryManagement"
// Common
import { Settings } from "pages/Settings"
// Utils
import { APP_ROUTES } from "../helpers/routeHelpers"
import { withTitle } from "../hoc"
import { PageTitles } from "../constants"

const departmentRoutes = [
  {
    path: APP_ROUTES.DASHBOARD,
    component: withTitle({
      component: DeparmentDashboard,
      title: PageTitles.DASHBOARD,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.SETTINGS,
    component: withTitle({
      component: Settings,
      title: PageTitles.SETTINGS,
      path: APP_ROUTES.SETTINGS,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.INVENTORY,
    component: withTitle({
      component: DeparmentInventory,
      title: PageTitles.INVENTORY,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.INVENTORY_REQUEST,
    component: withTitle({
      component: DeparmentInventoryRequest,
      title: PageTitles.INVENTORY_REQUEST,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.SCRAP_RETURN,
    component: withTitle({
      component: DeparmentScrapReturn,
      title: PageTitles.INVENTORY_REQUEST,
    }),
    exact: true,
  },
]
const warehouseRoutes = [
  {
    path: APP_ROUTES.DASHBOARD,
    component: withTitle({
      component: WarehouseDashboard,
      title: PageTitles.DASHBOARD,
    }),
    exact: true,
  },
  {
    path: APP_ROUTES.SETTINGS,
    component: withTitle({
      component: Settings,
      title: PageTitles.SETTINGS,
      path: APP_ROUTES.SETTINGS,
    }),
    exact: true,
  },

  {
    path: APP_ROUTES.INVENTORY,
    component: withTitle({
      component: WarehouseInventoryManagement,
      title: PageTitles.INVENTORY,
    }),
    exact: false,
  },
  {
    path: APP_ROUTES.INVENTORY_REQUEST,
    component: withTitle({
      component: WarehouseInventoryRequest,
      title: PageTitles.INVENTORY_REQUEST,
    }),
    exact: false,
  },
  {
    path: APP_ROUTES.ORDER_MANAGEMENT,
    component: withTitle({
      component: WarehouseOrderManagement,
      title: PageTitles.ORDER_MANAGEMENT,
    }),
    exact: true,
  },
]

const publicRoutes = [
  {
    path: APP_ROUTES.LOGIN,
    component: withTitle({
      component: Login,
      title: PageTitles.LOGIN,
    }),
  },
  {
    path: APP_ROUTES.FORGET_PASSWORD,
    component: withTitle({
      component: ForgetPassword,
      title: PageTitles.FORGET_PASSWORD,
    }),
  },
  {
    path: APP_ROUTES.FORGET_VERIFY_CODE,
    component: withTitle({
      component: ForgetVerifyCode,
      title: PageTitles.FORGET_VERIFY_CODE,
    }),
  },
  {
    path: APP_ROUTES.RESET_PASSWORD,
    component: withTitle({
      component: ResetPassword,
      title: PageTitles.RESET_PASSWORD,
    }),
  },
]

export { publicRoutes, departmentRoutes, warehouseRoutes }

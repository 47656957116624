import React, { useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import logoImage from "../../assets/images/signin/Casita-WebLogo.png"

import { withRouter, Link, Redirect } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { TextInput } from "../../components/TextInput"
import { Button } from "../../components/Button"
import { APP_ROUTES } from "../../helpers/routeHelpers"

// import images
import profile from "assets/images/profile-img.png"
import logo from "../../assets/images/logo-circle.png"
import { login } from "store/actions/authActions"
import { withAuthentication } from "hoc"
import { UserTypes } from "constants"

const Login = props => {
 
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email("Please enter a correct email address")
        .min(6, "Please enter an email address between 6 and 256 characters")
        .max(256, "Please enter an email address between 6 and 256 characters")
        .required("Please enter your email address"),
      password: Yup.string().trim().required("Please enter your password"),
    }),
    onSubmit: async ({ email, password }) => {
      try {
        setLoading(true)
        const res = await dispatch(
          login({
            Email: email,
            Password: password,
          })
        )
      } catch (err) {
      } finally {
        setLoading(false)
      } 
    },
  })

  return (
    <Container className="px-0 " fluid="xxl">
      {/* <Row className="justify-content-center"> */}
      <div className="limiter">
        <div className="container-login100 ">
          <div className="left-img-container login100-more d-none d-sm-block">
            <div className="mx-auto p-5 left-img-inner-con">
              <div className="left-img-div">
                <img src={logoImage} />
              </div>
              {/* <h1>Lorum Ipsum</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                lobortis maximus nunc, ac rhoncus odio congue quis. Sed ac
                semper orci, eu porttitor lacus.
              </p> */}
            </div>
          </div>
          <div className="wrap-login100 p-5 p-md-5 p-sm-2">
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <div className="mb-3 right-side-con">
                <h3 className="mb-4">Sign in</h3>
                <TextInput
                  inputClass="signin-inputs"
                  className="remove-validation-icons "
                  label="Email address"
                  name="email"
                  placeholder="carlos.carrillo@gmail.com"
                  type="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && !!validation.errors.email
                  }
                  valid={validation.touched.email && !validation.errors.email}
                />
                {validation.touched.email && !!validation.errors.email && (
                  <div className="form-input-error">
                    {validation.errors.email}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <TextInput
                  withIcon={true}
                  inputClass="signin-inputs"
                  className="d-flex flex-column remove-validation-icons"
                  label="Your password"
                  name="password"
                  placeholder="*************"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && !!validation.errors.password
                  }
                  valid={
                    validation.touched.password && !validation.errors.password
                  }
                />
                {validation.touched.password &&
                  !!validation.errors.password && (
                    <div className="form-input-error">
                      {validation.errors.password}
                    </div>
                  )}
              </div>
              <div className="d-flex justify-content-between">
                {/* <div className="form-check">
                  <TextInput
                    label="Remember me"
                    labelClass="mb-0 sign-in-remember"
                    type="checkbox"
                    inputClass="signin-remember-box"
                  />
                </div> */}
                <div className="form-check ps-0"> 
                  <Link
                    to={APP_ROUTES.FORGET_PASSWORD}
                    className="sign-in-forgetpass"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div> 
              <div className="mt-3 sign-in-btn">
                <Button
                  type="submit"
                  title="Sign In"
                  className="gt-btn-grad-primary"
                  isLoading={loading}
                />
              </div>
              {/* <div className="mt-4 text-center">
              <Link
                to={APP_ROUTES.FORGET_PASSWORD}
                className="text-muted fs-13"
              >
                <i className="mdi mdi-lock me-1 fs-21" />
                Forgot your password?
              </Link>
            </div> */}
            </Form>
          </div>
        </div>
      </div>
      {/* </Row> */}
    </Container>
    // <div className="account-pages my-5 pt-sm-5">
    //   <Container>
    //     <Row className="justify-content-center">
    //       <Col md={8} lg={6} xl={5}>
    //         <Card className="overflow-hidden gt-auth-card">
    //           <div className="gt-card-head-bgimg">
    //             <Row>
    //               <Col xs={7}>
    //                 <div className="text-primary p-4">
    //                   <h5 className="clr-blue">Welcome Back !</h5>
    //                   <h6 className="clr-blue fw-normal">
    //                     Sign in to continue to GYFT.
    //                   </h6>
    //                 </div>
    //               </Col>
    //               <Col className="col-5 align-self-end">
    //                 <img src={profile} alt="" className="img-fluid" />
    //               </Col>
    //             </Row>
    //           </div>
    //           <CardBody className="pt-0 bg-clr-body">
    //             <div>
    //               <Link to={APP_ROUTES.LOGIN} className="auth-logo-light">
    //                 <div className="avatar-md profile-user-wid mb-4">
    //                   <span className="avatar-title rounded-circle bg-light">
    //                     <img src={logo} alt="Gyft" className="rounded-circle" />
    //                   </span>
    //                 </div>
    //               </Link>
    //             </div>
    //             <div className="p-2">
    //               <Form
    //                 className="form-horizontal"
    //                 onSubmit={e => {
    //                   e.preventDefault()
    //                   validation.handleSubmit()
    //                   return false
    //                 }}
    //               >
    //                 <div className="mb-3">
    //                   <TextInput
    //                     className="remove-validation-icons"
    //                     label="Email"
    //                     name="email"
    //                     placeholder="Enter email"
    //                     type="email"
    //                     onChange={validation.handleChange}
    //                     onBlur={validation.handleBlur}
    //                     value={validation.values.email || ""}
    //                     invalid={
    //                       validation.touched.email && !!validation.errors.email
    //                     }
    //                     valid={
    //                       validation.touched.email && !validation.errors.email
    //                     }
    //                   />
    //                   {validation.touched.email &&
    //                     !!validation.errors.email && (
    //                       <div className="form-input-error">
    //                         {validation.errors.email}
    //                       </div>
    //                     )}
    //                 </div>
    //                 <div className="mb-3">
    //                   <TextInput
    //                     withIcon={true}
    //                     className="d-flex flex-column remove-validation-icons"
    //                     label="Password"
    //                     name="password"
    //                     placeholder="Enter password"
    //                     type="password"
    //                     onChange={validation.handleChange}
    //                     onBlur={validation.handleBlur}
    //                     value={validation.values.password || ""}
    //                     invalid={
    //                       validation.touched.password &&
    //                       !!validation.errors.password
    //                     }
    //                     valid={
    //                       validation.touched.password &&
    //                       !validation.errors.password
    //                     }
    //                   />
    //                   {validation.touched.password &&
    //                     !!validation.errors.password && (
    //                       <div className="form-input-error">
    //                         {validation.errors.password}
    //                       </div>
    //                     )}
    //                 </div>
    //                 <div className="form-check">
    //                   <TextInput
    //                     label="Remember me"
    //                     labelClass="mb-0"
    //                     type="checkbox"
    //                   />
    //                 </div>
    //                 <div className="mt-3 d-grid">
    //                   <Button
    //                     type="submit"
    //                     title="Sign In"
    //                     className="gt-btn-grad-primary"
    //                     isLoading={loading}
    //                   />
    //                 </div>
    //                 <div className="mt-4 text-center">
    //                   <Link
    //                     to={APP_ROUTES.FORGET_PASSWORD}
    //                     className="text-muted fs-13"
    //                   >
    //                     <i className="mdi mdi-lock me-1 fs-21" />
    //                     Forgot your password?
    //                   </Link>
    //                 </div>
    //               </Form>
    //             </div>
    //           </CardBody>
    //         </Card>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
  )
}

export default withAuthentication(withRouter(Login))

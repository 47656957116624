export const getItemThresholdClass = (
  availableQty = 0,
  fullStockThreshold = "",
  mediumStockThreshold = "",
  lowStockThreshold = ""
) => {
  if (availableQty < lowStockThreshold) {
    return "bg-red-light "
  } else if (
    availableQty > lowStockThreshold &&
    availableQty < mediumStockThreshold
  ) {
    return "bg-orange-light "
  } else if (
    availableQty > mediumStockThreshold
    // &&
    // availableQty >= fullStockThreshold
  ) {
    return "bg-green-light "
  }
}

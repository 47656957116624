import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

import { APP_ROUTES } from "../../helpers/routeHelpers"

import dashboardIcon from "assets/images/icons/wh-sd-dashboard-icon-wt.svg"
import invMangIcon from "assets/images/icons/wh-sd-inventory-icon-wt.svg"
import invReqIcon from "assets/images/icons/wh-sd-inv-req-icon-wt.svg"
import orderMangIcon from "assets/images/icons/wh-sd-order-mang-icon-wt.svg"
import settingsIcon from "assets/images/icons/wh-sd-settings-icon-wt.svg"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const currentPath = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")

      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (currentPath.includes(items[i].pathname)) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" className="gt-side-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="p">
              <Link to={APP_ROUTES.DASHBOARD} className="d-flex flex-column ">
                <img src={dashboardIcon} className="img-fluid cs-menu-icon" />
                <span className="font-size-13 mt-1 text-center">Dashboard</span>
              </Link>
              <hr width="70%" style={{ margin: "0 auto" }} />
            </li>
            <li>
              <Link
                to={APP_ROUTES.INVENTORY}
                className="d-flex flex-column align-items-center py-4"
              >
                <img src={invMangIcon} className="img-fluid cs-menu-icon" />
                <span className="font-size-13 mt-1 text-center">
                  Inventory Management
                </span>
              </Link>
              <hr width="70%" style={{ margin: "0 auto" }} />
            </li>
            <li>
              <Link
                to={APP_ROUTES.INVENTORY_REQUEST}
                className="d-flex flex-column align-items-center py-4"
              >
                <img src={invReqIcon} className="img-fluid cs-menu-icon" />
                <span className="font-size-13 mt-1 text-center">
                  Inventory Request
                </span>
              </Link>

              <hr width="70%" style={{ margin: "0 auto" }} />
            </li>
            <li>
              <Link
                to={APP_ROUTES.ORDER_MANAGEMENT}
                className="d-flex flex-column align-items-center py-4"
              >
                <img src={orderMangIcon} className="img-fluid cs-menu-icon" />
                <span className="font-size-13 mt-1 text-center">
                  Order Management
                </span>
              </Link>
              <hr width="70%" style={{ margin: "0 auto" }} />
            </li>
            <li>
              <Link
                to={APP_ROUTES.SETTINGS}
                className="d-flex flex-column align-items-center py-4"
              >
                <img src={settingsIcon} className="img-fluid cs-menu-icon" />
                <span className="font-size-13 mt-1 text-center">Settings</span>
              </Link>
              <hr width="70%" style={{ margin: "0 auto" }} />
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
}

export default withRouter(withTranslation()(SidebarContent))

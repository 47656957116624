import React, { useState, useEffect } from "react"
import { CardBody, Card } from "reactstrap"
import toast from "react-hot-toast"

import { DataTable } from "components/DataTable"
import { Button } from "components/Button"
import PencilIcon from "../../../assets/images/warehouse/inventory-management/pencil-icon.png"
import ActionEyeIcon from "../../../assets/images/inventory-request/action-eye-icon.png"
import DeleteIcon from "../../../assets/images/inventory/delete-icon.png"
import { SearchBox } from "components/SearchBox"
import { TableHeaderButtons } from "components/TableHeaderButtons"
import { PageLimit } from "components/PageLimit"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"
import { TextInput } from "components/TextInput"

import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import AddNewItemForm from "./AddNewItemForm"
import { AddNewItemModal as CreateOrder } from "../../Common/AddNewRequestModal"

import { ScrapReturnForm } from "../../Common/ScrapReturn"
import { WHIMViewDetail } from "../../Common/WHIMViewDetailModal"
import mockDataModals from "./ModalsData"
import MultiAddInventoryModal from './MultiAddInventoryModal';
import UploadInvFileModal from "./UploadInvFileModal"

import {
  WarehouseInventoryListing,
  GetItemsListing,
  AddWarehouseInventoryRequest,
  MarkInventoryUsage,
  AddNewItem,
  CreateScrapReturnRequest,
  CSVFileUpload
} from "api/api.service"
import { updateObjCheckBox } from "../../../utils/commonUtils"
import Config from "Config"
import { UsedCount } from "components/UsedCount"
import WarehouseInventPODetails from "./WarehouseInventPODetails"
import { getItemThresholdClass } from "utils/itemThresholdUtil"

const DATA_KEY = "data"
const TOTAL_KEY = "total"
const COLUMNS = {
  CHECK_BOXES: "",
  SKU_NO: "SKU No.",
  ITEM_NAME: "Item Name",
  INSTOCK_QUANTITY: "Instock (Quarters)",
  PRICE: "Unit Price ($)",
  ACTION: "Action",
  USAGE: "",
}
const ITEM_TYPE = {
  itemType: "Chemicals",
}

const WarehouseChemicalInventListing = () => {
  let controller = new AbortController()
  let signal = controller.signal
  const [isFileSubmitLoading, setIsFileSubmitLoading] = useState(false);
  const [isSubmittingScrap, setIsSubmittingScrap] = useState(false)
  const [selectedItemToView, setSelectedItemToView] = useState("")
  const [usedInvModal, setUsedInvenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [usedVlaue, setUsedValue] = useState("")
  const [isAddingRequest, setIsAddingRequest] = useState()
  const [modalDataList, setModalDataList] = useState([])
  const [dataRefetch, setDataRefetch] = useState(false)
  const [filters, setFilters] = useState(null)
  const [modalAddNewItemSelected, setModalAddNewItemSelected] = useState("")
  const [isAddNewItemLoading, setIsAddNewItemLoading] = useState(false)
  const [modalStates, setModalStates] = useState({
    addNewItemModal: false,
    createOrdersModal: false,
    scrapReturnModal: false,
    viewDetailModal: false,
    multiAddInventoryModal: false,
    invFileUploadModal: false,
  })
  const [createOrderListing, setCreateOrderListing] = useState({
    data: [],
    fetching: true,
    page: 1,
    Q: "",
    isDataAvailable: false,
  })
  const {
    page,
    searchText,
    handleSearchChange,
    handlePageClick,
    pageSize,
    onChangePageSize,
    request,
    data,
    total,
    isFetching,
  } = useLocalPaginatedRequest({
    requestFn: WarehouseInventoryListing,
    params: {
      ...(filters?.Status && { Status: filters?.Status }),
      ...{ ...ITEM_TYPE },
    },
    deps: [dataRefetch, filters],
    dataKey: DATA_KEY,
    totalKey: TOTAL_KEY,
  })

  const headerButtonsData = [
    {
      title: "Add New Item",
      onClick: () => setModalStates({ ...modalStates, multiAddInventoryModal: true }),
      classesButton: "ms-0 btn-alignment",
    },
    {
      title: "Create Order",
      onClick: () =>
        setModalStates({ ...modalStates, createOrdersModal: true }),
      classesButton: "ms-2 btn-alignment",
    },
    {
      title: "Scrap / Return",
      onClick: () => setModalStates({ ...modalStates, scrapReturnModal: true }),
      classesButton: "ms-2 btn-alignment",
    },
  ]

  // data update for modal on listing check/uncheck items
  const handleCheckBoxSelect = (e, item) => {
    item.requiredQuantity = ""
    item.quantityError = 1
    const data = updateObjCheckBox(item, modalDataList)
    setModalDataList(data)
  }
  const handleCloseModal = () => {
    setModalStates({
      scrapReturnModal: false,
      viewDetailModal: false,
      addNewItemModal: false,
      createOrdersModal: false,
      multiAddInventoryModal: false,
    })
    setModalDataList([])
    setSelectedItemToView("")
  }

  // handle select search
  const handleSearch = (value, callback) => {
    if (!value) return

    callback(prev => {
      return {
        ...prev,
        data: [],
        page: 1,
        Q: value,
      }
    })
  }

  // seperated as it need to perform more functionality
  const handleCreateOrdersModalClose = () => {
    setModalDataList([])
    setModalStates({ ...modalStates, createOrdersModal: false })
  }
  const handleAddNewItemModalClose = () => {
    setModalDataList([])
    setModalStates({ ...modalStates, addNewItemModal: false })
  }

  const handleMultiAddInvModalClose = () => {
    setModalStates({ ...modalStates, multiAddInventoryModal: false })
  }

  // department select API call
  useEffect(() => {
    getCreateOrderListingData(createOrderListing.page)

    return () => {
      controller.abort()
    }
  }, [createOrderListing.Q])

  // department select API call function
  const getCreateOrderListingData = page => {
    handleStateSet(setCreateOrderListing, { fetching: true, data: [] })
    GetItemsListing(
      {
        Limit: Config.LIMIT,
        ...(createOrderListing.Q && { Q: createOrderListing.Q }),
        Page: page,
      },
      signal
    )
      .then(res => {
        setCreateOrderListing(prev => ({
          ...prev,
          fetching: false,
          page: page + 1,
          isDataAvailable: res?.data.length === Config.LIMIT,
          data: (() => {
            let test = res?.data.map(item => ({
              ...item,
              value: item.id,
              label: item.itemName,
            }))
            return [...test, ...prev.data]
          })(),
        }))
      })
      .finally(() => {
        handleStateSet(setCreateOrderListing, { fetching: false })
      })
  }

  // handle data listing for modal
  const handleModalInputPriceValueChange = (e, id) => {
    let tempList = [...modalDataList]
    tempList.map(el => {
      if (el.id === id) {
        el.requiredQuantity = e.target.value
        if (e.target.value < 0) {
          el.quantityError = 2
          return false
        }
        if (e.target.value > 0 && e.target.value !== "") {
          el.quantityError = 0
        } else {
          el.quantityError = 1
        }
      }
    })
    setModalDataList(tempList)
  }

  // handle select Item in modal autocomplete
  const handleOnSelectOptionModalAutocomplete = e => {
    if (e) {
      const tempList = modalDataList.filter(el => el.skuNo == e.skuNo)
      e.requiredQuantity = ""
      e.quantityError = 1
      !tempList.length && setModalDataList([...modalDataList, e])
    }
    setModalAddNewItemSelected(e ? e.label : "")
  }
  // handle modal list delete item
  const handleModalListDelete = item => {
    const tempList = modalDataList.filter(el => el.id !== item.id)
    setModalDataList(tempList)
  }

  // handle stateset
  const handleStateSet = (callback = () => {}, keysObj = {}) => {
    callback(prev => {
      return {
        ...prev,
        ...(keysObj && keysObj),
      }
    })
  }

  // handle create order submit
  const handleCreateOrder = async e => {
    e.preventDefault()
    let errorCheck = [...modalDataList]
    errorCheck = modalDataList.filter(el => el.quantityError !== 0)
    if (errorCheck.length) {
      toast.error("Please insert the valid data in all fields.")
      return
    }
    let bodyData = { type: "Request", warehouseRequestItems: [] }
    modalDataList.map(el => {
      let tempObj = {}
      tempObj.itemsId = el.itemsId || el.id
      tempObj.quantity = el.requiredQuantity * 1
      tempObj.status = "Pending"
      bodyData.warehouseRequestItems.push(tempObj)
    })
    try {
      setIsAddingRequest(true)
      const res = await AddWarehouseInventoryRequest(bodyData)
      toast.success("Request has been created successfully!")
      handleCloseModal()
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsAddingRequest(false)
    }
  }

  const handleMarkInventoryUsage = async () => {
    if (!usedInvModal) return

    let payload = {
      itemsId: usedInvModal.itemsId,
      quantity: usedVlaue,
    }

    try {
      setIsLoading(true)
      let res = await MarkInventoryUsage(payload)
      toast.success("Success !")
      request()
    } catch (error) {
      toast.error(error.message)
    } finally {
      setUsedInvenModal("")
      setUsedValue("")
      setIsLoading(false)
    }
  }

  const handleSetUsed = evt => {
    if (evt.target.value > usedInvModal.quantity) return
    setUsedValue(evt.target.value)
  }

  // Add new item form submit
  const AddNewItemSubmit = async formData => {
    let payload = {
      // price: 0,
      itemName: formData.itemName,
      skuNo: formData.skuNo,
      itemType: formData.itemType,
      itemType: "Chemicals",
      description: formData.description,
      majorThreshold: Math.abs(formData.majorThreshold),
      minorThreshold: Math.abs(formData.minorThreshold),
      mediumThreshold: Math.abs(formData.mediumThreshold),
      itemsCategoryId: formData.itemsCategoryId,
    }

    try {
      setIsAddNewItemLoading(true)
      const response = await AddNewItem(payload)
      toast.success("Item has been added successfully.")
      handleAddNewItemModalClose()
      setIsAddNewItemLoading(false)
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsAddNewItemLoading(false)
    }
  }
  const handleSelectedItem = item => {
    setSelectedItemToView(item)
    setModalStates({
      ...modalStates,
      viewDetailModal: true,
    })
  }

  // Add Inventory Modal open handler
  const addInventoryModalOpen = () => {
    setModalStates({
      ...modalStates,
      multiAddInventoryModal: false,
      addNewItemModal: true,
    })
  }

  const handleInvFileUploadModalOpen = () => {
    setModalStates({
      ...modalStates,
      multiAddInventoryModal: false,
      invFileUploadModal: true,
    })
  }

  const handleInvFileUploadModalClose = () => {
    setModalStates({
      ...modalStates,
      invFileUploadModal: false,
    })
  }

  const handleSubmitScrap = async payload => {
    try {
      setIsSubmittingScrap(true)
      let res = await CreateScrapReturnRequest(payload)
      request()
      toast.success("Request has been created successfully !")
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsSubmittingScrap(false)
      handleCloseModal()
    }
  }

  const fileSubmitHandler = async (fileUploaded) => {
    console.log('flu', fileUploaded)
    let payload = new FormData();
    payload.append('file', fileUploaded)
    console.log(payload.get('file'))
    try {
    setIsFileSubmitLoading(true)
    await CSVFileUpload(payload)
    request()
    handleInvFileUploadModalClose()
  } catch (err) {
    toast.error(err.message)
  } finally {
    setIsFileSubmitLoading(false)
  }
}

  return (
    <>
      <Card className="mb-5">
        <CardBody className="p-2 p-md-4 wh-im-wh-ch-con">
          <div className="d-flex align-items-center justify-content-between mb-4 mt-2">
            <h5 className="fw-600 mb-0 table-main-heading wh-im-table-heading">
              Chemicals
            </h5>
            <div className="d-flex  mt-lg-0 me-0  wh-im-header-inner-btns-con ">
              <TableHeaderButtons
                data={headerButtonsData}
                classes="gt-btn-grad-primary px-2 px-md-4 wh-im-header-inner-btns btn-alignment"
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between  ">
            <PageLimit
              pageSize={pageSize}
              changePageSizeHandler={onChangePageSize}
              rowLimit={pageSize}
              typoClasses="page-limit-typo"
              selectClasses="page-limit-select"
            />
            <div>
              <SearchBox
                searchText={searchText}
                onChange={handleSearchChange}
                // labelClass="wh-im-search-label"
                inputClasses="table-main-search-input wh-im-search-input"
                // placeholder='Search...'
              />
            </div>
          </div>
          <DataTable
            data={data}
            loading={isFetching}
            className="responsive-table-whch"
            tableClasses="wh-chemicals-table"
            config={[
              {
                title: COLUMNS.CHECK_BOXES,
                render: data => {
                  return (
                    <div className="form-check">
                      <TextInput
                        // label="Remember me"
                        onClick={e => handleCheckBoxSelect(e, data)}
                        labelClass="mb-0"
                        type="checkbox"
                        inputClass="form-check-input-custom"
                        checked={
                          modalDataList.length > 0
                            ? modalDataList.filter(el => el.id === data.id)
                                .length
                            : false
                        }
                      />
                    </div>
                  )
                },
              },
              {
                title: COLUMNS.SKU_NO,
                render: data => {
                  return (
                    <>
                      <div
                        onClick={() => setInventoryDetailsModal(true)}
                        // className="table-clickable-column"
                      >
                        {data?.skuNo}
                      </div>
                    </>
                  )
                },
              },
              {
                title: COLUMNS.ITEM_NAME,
                render: data => {
                  return <div>{data?.itemName}</div>
                },
              },
              // {
              //   title: COLUMNS.QUANTITY,
              //   render: data => {
              //     return <>{getRandomBetweenTwoNum(1, 100)}</>
              //   },
              // },
              {
                title: COLUMNS.INSTOCK_QUANTITY,
                render: data => {
                  return (
                    <div
                      className={`wh-im-global-quantity ${getItemThresholdClass(
                        data?.globalTotal,
                        data?.majorThreshold,
                        data?.mediumThreshold,
                        data?.minorThreshold
                      )}`}
                    >
                      {data?.globalTotal}
                    </div>
                  )
                },
              },
              // {
              //   title: COLUMNS.PRICE,
              //   render: data => {
              //     return (
              //       <div
              //       // className={`wh-im-global-quantity ${getRandomBetweenTwoNum(1, 1000) < 100 ? "bg-red-light" : getRandomBetweenTwoNum(1, 1000) < 500 ? "bg-orange-light" : "bg-green-light"}`}
              //       >
              //         $90
              //       </div>
              //     )
              //   },
              // },

              {
                title: COLUMNS.ACTION,
                render: data => (
                  <>
                    <span
                      className="text-success pointer"
                      onClick={() => handleSelectedItem(data)}

                      // onClick={() => {
                      // setSelectedBusiness(data)
                      // {isActive.departmentRequest ? setDepartmentRequestModal(true): setPullbackRequestModal(true)}
                      // }}
                    >
                      <img src={ActionEyeIcon} />
                    </span>
                    {/* 
                    <span className="text-success pointer mx-1 mx-md-3">
                      <img src={PencilIcon} />
                    </span>
                   
                    Not decided yet
                    <span className="text-success pointer">
                      <img src={DeleteIcon} />
                    </span> */}
                  </>
                ),
              },
              {
                title: COLUMNS.USAGE,
                render: data => (
                  <>
                    <Button
                      onClick={() => setUsedInvenModal(data)}
                      size="sm"
                      title={<div>Add Usage</div>}
                      className="gt-btn-light-outline-type wh-im-wi-ch-btn"
                      styles={{ cursor: "pointer", color: "#a2a6aa" }}
                    />
                  </>
                ),
              },
            ]}
          />
          <Pagination
            currentPage={page}
            totalData={total}
            onPageClick={handlePageClick}
            rowLimit={pageSize}
          />
        </CardBody>
      </Card>

      {usedInvModal && (
        <Modal
          isOpen={usedInvModal}
          handleModalToggling={() => setUsedInvenModal("")}
          size="md"
        >
          <div className="d-flex flex-column align-items-center">
            <h3>USED ITEM</h3>
            <p>Items Used / Total in use</p>

            <UsedCount
              value={usedVlaue}
              onChange={e => handleSetUsed(e)}
              max={usedInvModal.quantity}
              total={usedInvModal?.quantity}
            />

            <Button
              disabled={usedInvModal?.quantity <= 0 || usedVlaue == 0}
              title="Done"
              className="gt-btn-grad-primary mt-5"
              isLoading={isLoading}
              onClick={handleMarkInventoryUsage}
            />
          </div>
        </Modal>
      )}
      <Modal
        isOpen={modalStates.addNewItemModal}
        handleModalToggling={handleAddNewItemModalClose}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        sizeClasses="5"
      >
        <AddNewItemForm
          modalCloseHandler={handleAddNewItemModalClose}
          title="Add New Item"
          subTitle="Chemicals"
          footerBtnType="Done"
          handleFormSubmit={AddNewItemSubmit}
          isLoading={isAddNewItemLoading}
        />
      </Modal>
      <Modal
        isOpen={modalStates.createOrdersModal}
        handleModalToggling={handleCreateOrdersModalClose}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        sizeClasses="5"
      >
        <CreateOrder
          modalCloseHandler={handleCreateOrdersModalClose}
          title="CREATE ORDER"
          footerBtnType="Confirm Order"
          deleteOption={true}
          isAddingRequest={isAddingRequest}
          createOrderListing={createOrderListing}
          handleSearch={handleSearch}
          setCreateOrderListing={setCreateOrderListing}
          handleOnSelectOptionModalAutocomplete={
            handleOnSelectOptionModalAutocomplete
          }
          modalDataList={modalDataList}
          handleModalInputPriceValueChange={handleModalInputPriceValueChange}
          handleModalListDelete={handleModalListDelete}
          handleCreateOrder={handleCreateOrder}
        />
      </Modal>

      <Modal
        isOpen={modalStates.viewDetailModal}
        handleModalToggling={handleCloseModal}
        sizeClasses="5"
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
      >
        <WarehouseInventPODetails
          modalCloseHandler={handleCloseModal}
          inventoryItem={selectedItemToView}
        />
      </Modal>

      <Modal
        isOpen={modalStates.scrapReturnModal}
        handleModalToggling={handleCloseModal}
      >
        <ScrapReturnForm
          quantityKey="quantity"
          params={{ ...ITEM_TYPE }}
          isReadOnly={false}
          isSubmitting={isSubmittingScrap}
          apiFunction={WarehouseInventoryListing}
          onSubmit={handleSubmitScrap}
        />
      </Modal>
      <Modal
        isOpen={modalStates.multiAddInventoryModal}
        handleModalToggling={handleMultiAddInvModalClose}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        size="sm"
      >
        <MultiAddInventoryModal
          modalCloseHandler={handleMultiAddInvModalClose}
          handleInvFileUploadModalOpen={handleInvFileUploadModalOpen}
          addInventoryModalOpen={addInventoryModalOpen}
          title="Add Items"
        />
      </Modal>
      <Modal
        isOpen={modalStates.invFileUploadModal}
        handleModalToggling={handleInvFileUploadModalClose}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        size="sm"
      >
        <UploadInvFileModal
          modalCloseHandler={handleInvFileUploadModalClose}
          title="ADD ITEMS"
          fileSubmitHandler={fileSubmitHandler}
          isFileSubmitLoading={isFileSubmitLoading}
        />
      </Modal>
    </>
  )
}

export default WarehouseChemicalInventListing

import * as Yup from "yup"

const initialValues = {
  id: "",
  itemType: "",
  skuNo: "",
  itemName: "",
  description: "",
  majorThreshold: "",
  minorThreshold: "",
  mediumThreshold: "",
  itemsCategoryId: "",
}

const validationSchema = Yup.object({
  id: Yup.number(),
  itemType: Yup.string().required("Item Type is required"),
  skuNo: Yup.string()
    .required("SKU NO. is required")
    .min(6, "SKU No. should be minimum 6 characters long")
    .max(25, "SKU No. should be between 6 to 25 characters"),
  itemName: Yup.string()
    .required("Item Name is required")
    .min(3, "Item Name should be minimum 6 characters long")
    .max(200, "Item Name should be between 6 to 25 characters"),
  description: Yup.string().required("Description is required"),
  // .min(6, "Description should be minimum 6 characters long"),
  majorThreshold: Yup.number()
    .typeError("Please enter a number")
    .required("Major Threshold is required")
    .test(
      "is-greater",
      "Full stock  should be greater than minor shortage",
      function (value) {
        return value > this.parent.mediumThreshold
      }
    ),
  minorThreshold: Yup.number()
    .typeError("Please enter a number")
    .required("Minor Threshold is required")
    .min(1),
  mediumThreshold: Yup.number()
    .typeError("Please enter a number")
    .required("Medium Threshold is required")
    .test(
      "is-greater",
      "Minor shortage should be greater than major shortage",
      function (value) {
        return value > this.parent.minorThreshold
      }
    ),

  itemsCategoryId: Yup.string().required("Category is required"),
})

export { initialValues, validationSchema }

import React, { useState, useEffect } from "react"
import { CardBody, Card } from "reactstrap"

import { DataTable } from "components/DataTable"
import { AutoComplete } from "components/AutoComplete"
import customStyles from "../../DepartmentApp/InventoryRequests/HeaderStatusStyling"
import PencilIcon from "../../../assets/images/warehouse/inventory-management/pencil-icon.png"
import ActionEyeIcon from "../../../assets/images/inventory-request/action-eye-icon.png"
import DeleteIcon from "../../../assets/images/inventory/delete-icon.png"
import { TableHeaderButtons } from "components/TableHeaderButtons"
import { PageLimit } from "components/PageLimit"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"
import { TextInput } from "components/TextInput"

import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { WHIMViewDetail } from "../../Common/WHIMViewDetailModal"
import mockDataModals from "./ModalsData"

import {
  GetDepartmentInventoryListing,
  GetDepartmentsListing,
  GetItemsListing,
} from "api/api.service"
import { updateObjCheckBox } from "utils/commonUtils"
import Config from "Config"
import CreatePullbackRequest from "./CreatePullbackRequest"
import { getItemThresholdClass } from "utils/itemThresholdUtil"
import GlobalQuantityModal from "../../Common/GlobalInventoryViewModal.js/GlobalQuantityModal"

const DATA_KEY = "data"
const TOTAL_KEY = "total"
const COLUMNS = {
  CHECK_BOXES: "",
  SKU_NO: "SKU No.",
  ITEM_NAME: "Item Name",
  DEPARTMENT: "Department",
  COMMITTED: "Committed",
  AVAILABLE: "Available",
  INSTOCK: "Total",
  GLOBAL_QUANTITY: "Global Quantity",
  ACTION: "Action",
}
const ITEM_TYPE = {
  itemType: "General",
}

const DepartmentGenInventListing = ({}) => {
  let controller = new AbortController()
  let signal = controller.signal

  const [itemDes, setItemDes] = useState("")
  const [selectedData, setSelectedData] = useState([])
  const [departmentListing, setDepartmentListing] = useState({
    data: [],
    fetching: true,
    page: 1,
    Q: "",
    isDataAvailable: false,
  })
  const [skuListing, setSKUListing] = useState({
    data: [],
    fetching: true,
    page: 1,
    Q: "",
    isDataAvailable: false,
  })

  const [filters, setFilters] = useState(null)
  const [selectedOption, setSelectedOption] = useState("")
  const [selectedRowID, setSelectedRowID] = useState({
    itemsId: "",
    itemName: "",
    itemSKU: "",
  })
  const [modalStates, setModalStates] = useState({
    viewDetailModal: false,
    pullbackRequestModal: false,
    globalQuantityModal: false,
    showItemDesModal: false,
  })

  useEffect(() => {
    getDepartmentsListingData(departmentListing.page)

    return () => {
      controller.abort()
    }
  }, [departmentListing.Q])

  useEffect(() => {
    getSKUListing(skuListing.page)

    return () => {
      controller.abort()
    }
  }, [skuListing.Q])

  const {
    page,
    pageSize,
    data,
    total,
    isFetching,
    handlePageClick,
    onChangePageSize,
  } = useLocalPaginatedRequest({
    requestFn: GetDepartmentInventoryListing,
    params: {
      ...(filters?.department && { departmentId: filters?.department }),
      ...{ ...ITEM_TYPE },
      ...(selectedOption !== "" && { skuNo: selectedOption }),
    },
    deps: [filters, selectedOption],
    dataKey: DATA_KEY,
    totalKey: TOTAL_KEY,
  })

  const headerButtonsData = [
    {
      title: "Pull Back Inventory",
      onClick: () =>
        setModalStates({ ...modalStates, pullbackRequestModal: true }),
      classesButton: "ms-0 btn-alignment",
    },
  ]

  const handleCheckBoxSelect = (e, item) => {
    item.requiredQuantity = ""
    const data = updateObjCheckBox(item, selectedData)
    setSelectedData(data)
  }

  const handleCloseModal = () => {
    setModalStates({
      viewDetailModal: false,
      pullbackRequestModal: false,
      globalQuantityModal: false,
      showItemDesModal: false,
    })
  }

  const handleSelectOptionDepartment = (name, option) => {
    let tempObj = [...option]
    let arrTemp = []
    tempObj = tempObj.map(el => arrTemp.push(el.value))
    setFilters({ ...filters, [name]: arrTemp.toString() })
  }

  const getDepartmentsListingData = page => {
    handleStateSet(setDepartmentListing, { fetching: true, data: [] })
    GetDepartmentsListing(
      {
        limit: Config.LIMIT,
        ...(departmentListing.Q && { Q: departmentListing.Q }),
        page: page,
      },
      signal
    )
      .then(res => {
        setDepartmentListing(prev => ({
          ...prev,
          fetching: false,
          page: page + 1,
          isDataAvailable: res?.data?.length === Config.LIMIT,
          data: (() => {
            let test = res?.data?.map(item => ({
              ...item,
              value: item.id,
              label: item.departmentName,
            }))
            return [...test, ...prev.data]
          })(),
        }))
      })
      .finally(() => {
        handleStateSet(setDepartmentListing, { fetching: false })
      })
  }
  const getSKUListing = page => {
    handleStateSet(setSKUListing, { fetching: true, data: [] })
    GetItemsListing(
      {
        limit: Config.LIMIT,
        ...(skuListing.Q && { Q: skuListing.Q }),
        page: page,
      },
      signal
    )
      .then(res => {
        setSKUListing(prev => ({
          ...prev,
          fetching: false,
          page: page + 1,
          isDataAvailable: res?.data?.length === Config.LIMIT,
          data: (() => {
            let test = res?.data?.map(item => ({
              ...item,
              value: item.skuNo,
              label: item.skuNo,
            }))
            return [...test, ...prev.data]
          })(),
        }))
      })
      .finally(() => {
        handleStateSet(setSKUListing, { fetching: false })
      })
  }
  const handleSearch = (value, callback) => {
    if (!value) return

    callback(prev => {
      return {
        ...prev,
        data: [],
        page: 1,
        Q: value,
      }
    })
  }

  const handleStateSet = (callback = () => {}, keysObj = {}) => {
    callback(prev => {
      return {
        ...prev,
        ...(keysObj && keysObj),
      }
    })
  }

  const handleSelectOption = e => {
    setSelectedOption(e ? e.label : "")
  }

  const handleCreatePullbackModalClose = () => {
    setModalStates({ ...modalStates, pullbackRequestModal: false })
    setSelectedData("")
  }

  const GlobalQuantityHandler = (id, name, SKU) => {
    setSelectedRowID({
      ...selectedData,
      itemsId: id,
      itemName: name,
      itemSKU: SKU,
    })
    setModalStates({
      ...modalStates,
      globalQuantityModal: true,
    })
  }
  const handleOpenDesModal = data => {
    setItemDes(data)
    setModalStates(prev => ({ ...prev, showItemDesModal: true }))
  }

  return (
    <>
      <Card className="mb-5">
        <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
          <div className="d-flex align-items-center justify-content-between mb-4 mt-2">
            <h5 className="fw-600 mb-0 table-main-heading wh-im-table-heading">
              General Inventory
            </h5>
            <div className="d-flex  mt-lg-0 me-0  wh-im-header-inner-btns-con ">
              <TableHeaderButtons
                data={headerButtonsData}
                classes={
                  "gt-btn-grad-primary px-2 px-md-4 wh-im-header-inner-btns btn-alignment"
                }
              />
            </div>
          </div>
          <div className="d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row mb-3 mb-md-0">
            <PageLimit
              pageSize={pageSize}
              changePageSizeHandler={onChangePageSize}
              rowLimit={pageSize}
              typoClasses="page-limit-typo"
              selectClasses="page-limit-select"
              mainClass="mt-0 mb-3 my-md-3"
            />
            <div className="d-flex align-items-center justify-content-center  ">
              <div className="status-search-con wh-im-dpgi-status me-2">
                <AutoComplete
                  handleBlur={() =>
                    setDepartmentListing(prev => {
                      return {
                        ...prev,
                        page: 1,
                        Q: "",
                      }
                    })
                  }
                  placeholder="All Departments"
                  customStyles={customStyles.WarehouseIM}
                  onInputChange={val => handleSearch(val, setDepartmentListing)}
                  onChange={val =>
                    handleSelectOptionDepartment("department", val)
                  }
                  isLoading={departmentListing?.fetching}
                  isMulti
                  classNamePrefix="status-header-search"
                  options={departmentListing?.data}
                />
              </div>
              <div className="status-search-con wh-im-dpgi-status">
                <AutoComplete
                  handleBlur={() =>
                    setSKUListing(prev => {
                      return {
                        ...prev,
                        page: 1,
                        Q: "",
                      }
                    })
                  }
                  placeholder="Select SKU Number"
                  customStyles={customStyles.WarehouseIM}
                  onChange={handleSelectOption}
                  onInputChange={val => handleSearch(val, setSKUListing)}
                  closeMenuOnSelect={true}
                  isClearable={true}
                  value={skuListing?.data?.filter(
                    x => selectedOption === x.label
                  )}
                  isLoading={skuListing?.fetching}
                  classNamePrefix="status-header-search"
                  options={skuListing?.data}
                />
              </div>
            </div>
          </div>
          <DataTable
            data={data}
            loading={isFetching}
            tableClasses="dp-gi-table"
            config={[
              {
                title: COLUMNS.CHECK_BOXES,
                render: data => {
                  return (
                    <div className="form-check">
                      <TextInput
                        onClick={e => handleCheckBoxSelect(e, data)}
                        labelClass="mb-0"
                        type="checkbox"
                        inputClass="form-check-input-custom"
                        checked={
                          selectedData.length > 0
                            ? selectedData.filter(el => el.id === data.id)
                                .length
                            : false
                        }
                      />
                    </div>
                  )
                },
              },
              {
                title: COLUMNS.SKU_NO,
                render: data => {
                  return data?.skuNo || "-"
                },
              },
              {
                title: COLUMNS.ITEM_NAME,
                render: data => {
                  return data?.itemName || "-"
                },
              },
              {
                title: COLUMNS.DEPARTMENT,
                render: data => {
                  return data?.department?.departmentName || "-"
                },
              },
              {
                title: COLUMNS.COMMITTED,
                render: data => {
                  return data?.committed || "-"
                },
              },
              {
                title: COLUMNS.AVAILABLE,
                render: data => {
                  return data?.inStock - data?.committed || "-"
                },
              },
              {
                title: COLUMNS.INSTOCK,
                render: data => {
                  return data?.inStock || "-"
                },
              },
              {
                title: COLUMNS.GLOBAL_QUANTITY,
                render: data => {
                  return (
                    <div
                      className={`wh-im-global-quantity ${getItemThresholdClass(
                        data?.globalTotal,
                        data?.majorThreshold,
                        data?.mediumThreshold,
                        data?.minorThreshold
                      )}`}
                      onClick={e =>
                        GlobalQuantityHandler(
                          data?.itemsId,
                          data?.itemName,
                          data?.skuNo
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {data?.globalTotal || "-"}
                    </div>
                  )
                },
              },
              {
                title: COLUMNS.ACTION,
                render: data => (
                  <>
                    <span
                      className="text-success pointer"
                      onClick={() => handleOpenDesModal(data)}
                    >
                      <img src={ActionEyeIcon} />
                    </span>
                  </>
                ),
              },
            ]}
          />
          <Pagination
            currentPage={page}
            totalData={total}
            onPageClick={handlePageClick}
            rowLimit={pageSize}
          />
        </CardBody>
      </Card>

      <Modal
        isOpen={modalStates.pullbackRequestModal}
        handleModalToggling={handleCreatePullbackModalClose}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        sizeClasses="5"
      >
        <CreatePullbackRequest
          params={{ ...ITEM_TYPE }}
          modalCloseHandler={handleCreatePullbackModalClose}
          selectedData={selectedData}
        />
      </Modal>

      <Modal
        isOpen={modalStates.showItemDesModal}
        handleModalToggling={handleCloseModal}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        sizeClasses="5"
      >
        <h3 className="text-center inv-req-popup-heading">{`${itemDes?.itemName} (${itemDes.skuNo})`}</h3>
        <div className="p-4">
          <h4 className="inventory-description">Product Summary</h4>
          <p className="mt-1">{`${itemDes?.description || "-"}`}</p>
        </div>
      </Modal>

      <Modal
        isOpen={modalStates.globalQuantityModal}
        handleModalToggling={handleCloseModal}
        sizeClasses="5"
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
      >
        <GlobalQuantityModal
          modalCloseHandler={handleCloseModal}
          selectedRowData={selectedRowID}
        />
      </Modal>
    </>
  )
}

export default DepartmentGenInventListing

import React, { useState } from "react"
import { CardBody, Container, Row, Col, Card } from "reactstrap"
import { useHistory } from "react-router-dom"

import InventoryRequestIcon from "assets/images/warehouse/inventory-request/wh-rm-header-icon.png"
import ActionEyeIcon from "assets/images/inventory-request/action-eye-icon.png"
import ViewOrderItemsModal from "./ViewOrderItemsModal"
import { TableTopHeading } from "components/TableTopHeading"
import { BackButtonTableTop } from "components/BackButtonTableTop"
import { SearchBox } from "components/SearchBox"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { PageLimit } from "components/PageLimit"
import { Button } from "components/Button"
import { Modal } from "components/Modal"
import { GetWarehouseOrdersListing } from "../../../api/api.service"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { RequestStatus } from "constants"
import { dateTimeFormat, convertDateTime } from "../../../utils/dateFormatUtils"
import { AutoComplete } from "components/AutoComplete"
import { getAppName } from "utils/commonUtils"

const COLUMNS = {
  REQUEST_NO: "Order No.",
  DATE_OF_ORDER: "Ordered Date",
  DEPARTMENT: "Department",
  STATUS: "Status",
  ACTION: "Action",
}

const dataKey = "data"
const totalKey = "total"

const OrderManagementListing = ({}) => {
  const history = useHistory()

  const [statusFilters, setStatusFilters] = useState(null)
  const [modalToggle, setModalToggle] = useState(false)
  const [selectedData, setSelectedData] = useState([])

  const {
    data,
    page,
    pageSize,
    isFetching,
    searchText,
    total,
    handleSearchChange,
    handlePageClick,
    onChangePageSize,
  } = useLocalPaginatedRequest({
    requestFn: GetWarehouseOrdersListing,
    params: {
      ...(statusFilters?.value && { requestStatus: statusFilters?.value }),
    },
    deps: [statusFilters],
    dataKey: dataKey,
    totalKey: totalKey,
  })

  const statusSelectOptions = [
    {
      label: "Open",
      value: "Open",
    },
    {
      label: "In Progress",
      value: "InProgress",
    },
    {
      label: "Completed",
      value: "Completed",
    },
  ]

  const handleSelectStatusOption = option => {
    setStatusFilters(option)
  }

  const handleModalToggle = () => {
    setModalToggle(!modalToggle)
  }

  const handleSetDetailsDataAndModal = data => {
    setSelectedData(data)
    setModalToggle(true)
  }

  return (
    <React.Fragment>
      <div className="gt-business-accounts">
        <Container fluid>
          <Row>
            <Col className="col-12 px-lg-3 px-2 order-management-con">
              <div className="d-flex align-items-center mb-4 mb-4 mt-lg-0 mt-3">
                <BackButtonTableTop />
              </div>
              <div className="d-flex align-items-center top-heading-con mb-2 order-man-top-heading">
                <TableTopHeading
                  title={`Order Management (${getAppName()})`}
                  className="me-4 top-heading-typo"
                  iconImage={InventoryRequestIcon}
                  iconClass="inventory-req-icon"
                />

                <div style={{ width: "200px" }}>
                  <AutoComplete
                    value={statusFilters}
                    isClearable
                    onChange={val => handleSelectStatusOption(val)}
                    isSearchable={false}
                    placeholder="Select status"
                    classNamePrefix="status-header-search"
                    options={statusSelectOptions}
                    className="w-100 order-man-select"
                  />
                </div>
              </div>

              <Card className="mb-5">
                <CardBody className="p-2 p-md-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="fw-600 mb-0 table-main-heading">Orders</h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <PageLimit
                      pageSize={pageSize}
                      changePageSizeHandler={onChangePageSize}
                      rowLimit={pageSize}
                      typoClasses="page-limit-typo"
                      selectClasses="page-limit-select"
                    />
                    <div>
                      <SearchBox
                        searchText={searchText}
                        onChange={handleSearchChange}
                        inputClasses="table-main-search-input wh-im-search-input"
                        // placeholder='Search...'
                      />
                    </div>
                  </div>
                  <DataTable
                    data={data}
                    loading={isFetching}
                    config={[
                      {
                        title: COLUMNS.REQUEST_NO,
                        render: data => data.orderNo,
                      },
                      {
                        title: COLUMNS.DATE_OF_ORDER,
                        render: data =>
                          convertDateTime({
                            date: data.createdAt,
                            customFormat: dateTimeFormat.casitaCutomDateFormat,
                            dateOnly: true,
                          }),
                      },
                      // {
                      //   title: COLUMNS.DEPARTMENT,
                      //   render: data => data?.departmentName,
                      // },
                      {
                        title: COLUMNS.STATUS,
                        render: data => {
                          return (
                            <div
                              className={`inv-req-popup-status ${RequestStatus.getLabelClass(
                                data.requestStatus
                              )}`}
                            >
                              {RequestStatus.getDisplayTextKey(
                                data.requestStatus
                              )}
                            </div>
                          )
                        },
                      },
                      {
                        className: "text-center",
                        title: COLUMNS.ACTION,
                        render: data => {
                          return (
                            <span
                              className="text-success pointer"
                              onClick={() => {
                                handleSetDetailsDataAndModal(data)
                              }}
                            >
                              <img src={ActionEyeIcon} />
                            </span>
                          )
                        },
                      },
                    ]}
                  />
                  <Pagination
                    currentPage={page}
                    totalData={total}
                    onPageClick={handlePageClick}
                    rowLimit={pageSize}
                  />
                </CardBody>
              </Card>
              <div className="text-center d-flex justify-content-center align-items-center">
                <Button
                  size="lg"
                  className="gt-btn-grad-primary table-bottom-btn btn-alignment ln-height-1"
                  title="Back to Main"
                  onClick={history.goBack}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={modalToggle}
          handleModalToggling={handleModalToggle}
          bodyClassName="p-0"
          customButton={true}
          hideModalHeaderSeparator={true}
          headerClasses="pb-0"
          className="inv-req-popup-viewdetails"
        sizeClasses="5"
        >
          <ViewOrderItemsModal
            modalCloseHandler={handleModalToggle}
            data={selectedData}
          />
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default OrderManagementListing

import React, { useState, useEffect } from "react"
import toast from "react-hot-toast"
import { DataTable } from "components/DataTable"
import { TextInput } from "components/TextInput"
import { AutoComplete } from "components/AutoComplete"
import { Button } from "components/Button"

import {
  GetItemsListing,
  AddDeparmentInventoryRequest,
} from "../../../api/api.service"

import DeleteIcon from "../../../assets/images/inventory/delete-icon.png"

import AddNewRequestSelect from "./HeaderStatusStyling"
import Config from "Config"
import { validateOptionSelect, makeOptionsDisabled } from "utils/commonUtils"
import { validateZero } from "utils/formUtils"

const COLUMNS = {
  SKU_NO: "SKU No.",
  ITEM_NAME: "Item Name",
  REQUIRED_QUANTITY: "Required Quantity",
  ACTION: "",
}

const AddNewItemModal = ({ modalCloseHandler, selectedData = [] }) => {
  let controller = new AbortController()
  let signal = controller.signal

  const [isAddingRequest, setIsAddingRequest] = useState()
  const [selectedItems, setSelectedItems] = useState(null)
  const [previousSelectedItems, setPreviousSelectedItems] = useState([
    ...selectedData,
  ])

  const [itemsListing, setItemsListing] = useState({
    data: [],
    fetching: true,
    page: 1,
    isDataAvailable: true,
  })

  useEffect(() => {
    itemsListing.page && handleGetItemsListing(itemsListing.page)

    return () => {
      controller.abort()
    }
  }, [itemsListing.Q])

  const handleSelectOption = option => {
    if (
      typeof option != "object" ||
      validateOptionSelect(option, previousSelectedItems, "id") ||
      !Object.keys(option)
    )
      return
    setPreviousSelectedItems(prev => {
      return [...prev, { ...option, requiredQuantity: "", hasError: false }]
    })
  }
  const handleSearch = (value = "", callback) => {
    if (!value) return

    callback(prev => {
      return {
        ...prev,
        data: [],
        page: 1,
        Q: value,
      }
    })
  }

  const handleGetItemsListing = page => {
    let itemsIdArr, test
    if (previousSelectedItems.length) {
      itemsIdArr = previousSelectedItems?.map(item => item.itemsId)
    }

    setItemsListing(prev => {
      return {
        ...prev,
        fetching: true,
      }
    })
    GetItemsListing(
      {
        Limit: Config.LIMIT,
        ...(itemsListing.Q && { Q: itemsListing.Q }),
        Page: page,
      },
      signal
    )
      .then(res => {
        setItemsListing(prev => ({
          ...prev,
          fetching: false,
          page: page + 1,
          isDataAvailable: res?.data?.length == Config.LIMIT,
          data: (() => {
            test = res?.data?.map(item => ({
              ...item,
              value: item.id,
              label: item.itemName,
            }))

            if (previousSelectedItems.length) {
              test = test.filter(item => !itemsIdArr.includes(item.id))
            }

            return [...test]
          })(),
        }))
      })
      .finally(() => {
        setItemsListing(prev => {
          return {
            ...prev,
            fetching: false,
          }
        })
      })
  }

  const handleSetItemQuantity = (evt, targetObj, index) => {
    let indexOfTargetObj = index
    let stateCopy = [...previousSelectedItems]
    let refObj = (stateCopy[indexOfTargetObj] = {
      ...targetObj,
    })

    refObj.requiredQuantity = evt.target.value
    setPreviousSelectedItems(stateCopy)
  }

  const handleDeleteSelectedItem = (id = "",sku) => {
    if (!id) return

    let filteredData = previousSelectedItems.filter(obj => obj.id != id)
    setPreviousSelectedItems([...filteredData])
    if (filteredData.length < 1) {
      setSelectedItems(null)
    }
    itemsListing?.data.map(el => {
      if(el.skuNo === sku){
        el.isDisabled = false
      }
    })
  }

  const handleConfirmRequest = async () => {
    if (validateZero(previousSelectedItems, "requiredQuantity")) {
      toast.error("Items requested quantity can't be 0 !")
      return
    }

    let formattedItemsList = previousSelectedItems.map(obj => {
      return {
        itemsId: obj.itemsId || obj.id,
        quantity: Math.abs(obj.requiredQuantity),
        // Hardcoded for now but need to be change
        status: "Pending",
      }
    })

    let bodyData = {
      // Hardcoded for now but need to be change
      type: "Request",
      departmentRequestItems: formattedItemsList,
    }

    try {
      setIsAddingRequest(true)
      let res = await AddDeparmentInventoryRequest(bodyData)
      toast.success("Request has been created successfully !")
      setSelectedItems(null)
      setPreviousSelectedItems("")
      setItemsListing([])
      modalCloseHandler()
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsAddingRequest(false)
    }
  }

  return (
    <>
      <h3 className="text-center inv-req-popup-heading">Add New Request</h3>
      <div className="pt-3 pb-4 add-new-req-select-con">
        <span className="font-size-13">Add Items : </span>
        <AutoComplete
          placeholder="Search Item"
          classNamePrefix="add-new-req"
          value={selectedItems}
          customStyles={AddNewRequestSelect}
          onInputChange={val => handleSearch(val, setItemsListing)}
          options={makeOptionsDisabled(previousSelectedItems, itemsListing)}
          onChange={val => handleSelectOption(val)}
          closeMenuOnSelect={true}
          isLoading={itemsListing?.fetching}
          handleBlur={() =>
            setItemsListing(prev => {
              return {
                ...prev,
                page: 1,
                Q: "",
              }
            })
          }
        />
      </div>
      <DataTable
        data={previousSelectedItems}
        className="view-detail-inv-req-table add-req-inv-con height-scroll-table"
        tableClasses="add-new-request-table"
        config={[
          {
            title: COLUMNS.SKU_NO,
            render: data => {
              return data?.skuNo
            },
          },
          {
            title: COLUMNS.ITEM_NAME,
            render: data => {
              return data?.itemName
            },
          },
          {
            title: COLUMNS.REQUIRED_QUANTITY,
            render: (data, idx) => {
              return (
                <div className="">
                  <TextInput
                    // label="Remember me"
                    onChange={evt => handleSetItemQuantity(evt, data, idx)}
                    className="view-detail-inv-req-p-ip-con me-4 me-md-5"
                    labelClass="d-none"
                    type="number"
                    min={1}
                    inputClass="view-detail-inv-req-p-ip"
                    value={data.requiredQuantity}
                    placeholder="Quantity"
                  />
                </div>
              )
            },
          },

          {
            title: COLUMNS.ACTION,
            render: data => {
              return (
                <img
                  onClick={() => handleDeleteSelectedItem(data.id, data.skuNo)}
                  className="view-detail-modal-delete"
                  src={DeleteIcon}
                />
              )
            },
          },
        ]}
      />
      <div className="text-center my-4">
        <Button
          disabled={!Boolean(previousSelectedItems?.length)}
          isLoading={isAddingRequest}
          size="lg"
          className="gt-btn-grad-primary table-bottom-btn m-0 p-0 btn-alignment"
          title="Confirm Request"
          onClick={handleConfirmRequest}
        />
      </div>
    </>
  )
}

export default AddNewItemModal

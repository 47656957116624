import React from "react"

import { DataTable } from "components/DataTable"
import { Select } from "components/Select"
import { TextInput } from "components/TextInput"
import { AutoComplete } from "components/AutoComplete"
import { Button } from "components/Button"
import customStyles from "./HeaderStatusStyling"
import { InventoryReqItemsStatus } from "constants"
import useGetPdfReport from "hooks/useGetPdfReport"

const PDF_TYPE = "Department"

const COLUMNS = {
  ITEM_NAME: "Item Name",
  SUBTITUTED_ITEM_NAME: "Subsitute Name",
  SKU_NO: "SKU No.",
  QUANTITY_REQUESTED: "Quantity Requested",
  STATUS: "Status",
  STATUS_UPDATE: "Status Update",
  QUANTITY_DELIVERED: "Quantity Received",
}

const ViewDetailsInventoryRequest = ({ modalCloseHandler, data = {} }) => {
  const { fetchingReport, handleGetPdfReport, reportData } = useGetPdfReport()

  return (
    <>
      <h3 className="text-center inv-req-popup-heading">
        Request Id : {data.id || "-"}
      </h3>
      <DataTable
        data={data.items}
        className="view-detail-inv-req-table height-scroll-table"
        tableClasses="dpt-request-detail-table"
        config={[
          {
            title: COLUMNS.ITEM_NAME,
            render: data => {
              return data?.itemName
            },
          },
          {
            title: COLUMNS.SUBTITUTED_ITEM_NAME,
            render: data => {
              return data?.substitute?.itemName || "-"
            },
          },
          {
            title: COLUMNS.SKU_NO,
            render: data => {
              return data?.skuNo
            },
          },
          {
            title: COLUMNS.QUANTITY_REQUESTED,
            render: data => {
              return data?.quantity
            },
          },
          {
            title: COLUMNS.STATUS,
            render: data => {
              return (
                <div
                  className={`inv-req-popup-status ${InventoryReqItemsStatus.getLabelClass(
                    data.status
                  )}`}
                >
                  {InventoryReqItemsStatus.getDisplayTextKey(data.status)}
                </div>
              )
            },
          },
          {
            title: COLUMNS.QUANTITY_DELIVERED,
            render: data => {
              return data?.quantitySupplied
            },
          },
          // {
          //   title: COLUMNS.STATUS_UPDATE,
          //   className: "status-col-inv-req",
          //   render: data => {
          //     return (
          //       <AutoComplete
          //         placeholder="Select Product"
          //         classNamePrefix="view-detail-select"
          //         customStyles={customStyles.ModalVD}
          //         options={[
          //           {
          //             label: "product#1",
          //             value: 2,
          //           },
          //           {
          //             label: "product#2",
          //             value: 2,
          //           },
          //           {
          //             label: "product#3",
          //             value: 3,
          //           },
          //           {
          //             label: "product#4",
          //             value: 4,
          //           },
          //         ]}
          //       />
          //     )
          //   },
          // },
          // {
          //   title: COLUMNS.QUANTITY_DELIVERED,
          //   render: data => {
          //     return (
          //       // <div >
          //       <TextInput
          //         // label="Remember me"
          //         // onClick={(e) => handleCheckBoxSelect(e, data)}
          //         className="view-detail-inv-req-p-ip-con"
          //         placeholder=""
          //         labelClass="d-none"
          //         type="text"
          //         inputClass="view-detail-inv-req-p-ip"
          //       />
          //       // </div>
          //     )
          //   },
          // },
        ]}
      />
      <div className="text-center my-4">
        <Button
          size="lg"
          className="gt-btn-grad-primary table-bottom-btn mx-2"
          title={<div>Done</div>}
          onClick={() => modalCloseHandler(false)}
        />
        <Button
          isLoading={fetchingReport}
          size="lg"
          className="gt-btn-grad-primary table-bottom-btn mx-2 px-5 btn-alignment"
          title={<div>Print Order</div>}
          onClick={() => handleGetPdfReport(data?.id, PDF_TYPE)}
        />
      </div>
    </>
  )
}

export default ViewDetailsInventoryRequest

import moment from "moment"

export const downloadCSVData = csvText => {
  let hiddenElement = document.createElement("a")
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvText)
  hiddenElement.target = "_blank"
  hiddenElement.download = `template_${new Date().getTime()}.csv`
  hiddenElement.click()
}

export const downloadXlsData = async response => {
  const blob = await response.blob()

  const url = window.URL.createObjectURL(blob)
  let a = document.createElement("a")
  a.href = url
  a.download = `template_${new Date().getTime()}.xlsx`
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const downloadPdf = async response => {
  const blob = await response.blob()
  const url = window.URL.createObjectURL(blob)
  let a = document.createElement("a")
  a.href = url
  a.download = `${moment().format("MMMM-Do-YYYY")}.pdf`
  document.body.appendChild(a)
  a.click()
  a.remove()
}

// styling header status
const customStyles = {
  ModalVD: {
    container: provided => ({
      display: "inline-block",
      position: "relative",
      width: "100%",
      background: "#FFFFFF",
      border: "1px solid #CED4DA",
      borderRadius: "4px",
      width: "130px",
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      // borderRadius: "5px",
      minHeight: "45px",
      borderColor: "#9d9d9d",
      // backgroundColor: "#eef9ff",
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        borderColor: "#9d9d9d",
      },
    }),
  },
  InventoryHeader: {
    container: provided => ({
      display: "inline-block",
      position: "relative",
      width: "70%",
      background: "#FFFFFF",
      border: "1px solid #CED4DA",
      borderRadius: "4px",
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: "45px",
      borderColor: "#9d9d9d",
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        borderColor: "#9d9d9d",
      },
    }),
  },
  WarehouseIM: {
    container: provided => ({
      display: "inline-block",
      position: "relative",
      width: "100%",
      background: "#FFFFFF",
      border: "1px solid #CED4DA",
      borderRadius: "4px",
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: "45px",
      borderColor: "#9d9d9d",
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        borderColor: "#9d9d9d",
      },
    }),
  },
  IMRequests: {
    container: provided => ({
      display: "inline-block",
      position: "relative",
      width: "100%",
      background: "#FFFFFF",
      border: "1px solid #CED4DA",
      borderRadius: "4px",
      // height: "30px",
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: "45px",
      borderColor: "#9d9d9d",
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      height: "33px",
      "&:hover": {
        borderColor: "#9d9d9d",
      },
    }),
  },
}

export default customStyles

import React, { useState } from "react"
import {
  Container,
  Form,
} from "reactstrap"
import { toast } from "react-hot-toast"
import logoImage from "../../assets/images/signin/Casita-WebLogo.png"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link, useHistory, Redirect } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { TextInput } from "../../components/TextInput"
import { Button } from "../../components/Button"
import { Alert } from "../../components/Alert"
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo-circle.png"
import { withAuthentication } from "hoc"
import { ForgotPassword } from "api/api.service"

const ForgetPassword = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email("Please enter a correct email address")
        .min(6, "Please enter an email address between 6 and 256 characters")
        .max(256, "Please enter an email address between 6 and 256 characters")
        .required("Please enter your email address"),
    }),
    onSubmit: async values => {
      const { email } = values
      try {
        setLoading(true)
        const res = await ForgotPassword({ email: email })
        toast.success("Password has been sent to your email ! Kindly check your email.")
        setTimeout(() => history.push(APP_ROUTES.LOGIN), 1000)
        // res?.TokenUUID &&
        //   history.push({
        //     pathname: getRoute(APP_ROUTES.FORGET_VERIFY_CODE),
        //     search: new URLSearchParams({ token: res.TokenUUID }).toString(),
        //     state: { email },
        //   })
      } catch (err) {
        toast.error(err.message)
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <Container className="px-0 " fluid="xxl">
      {/* <Row className="justify-content-center"> */}
      <div className="limiter">
        <div className="container-login100 ">
          <div className="left-img-container login100-more d-none d-sm-block">
            <div className="mx-auto p-5 left-img-inner-con">
              <div className="left-img-div">
                <img src={logoImage} />
              </div>
              {/* <h1>Lorum Ipsum</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                lobortis maximus nunc, ac rhoncus odio congue quis. Sed ac
                semper orci, eu porttitor lacus.
              </p> */}
            </div>
          </div>
          <div className="wrap-login100 p-5 p-md-5 p-sm-2">
              <Form
                className="form-classed"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="mb-3 right-side-con">
                  <h1 className="mb-4">Forgot your password?</h1>
                  <TextInput
                    inputClass="signin-inputs"
                    className="remove-validation-icons "
                    label="Email"
                    name="email"
                    placeholder="Enter email"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={validation.touched.email && !!validation.errors.email}
                    valid={validation.touched.email && !validation.errors.email}
                  />
                  {validation.touched.email && !!validation.errors.email && (
                    <div className="form-input-error">{validation.errors.email}</div>
                  )}
                </div>

                <div className="mt-3 sign-in-btn">
                  <Button
                    size="sm"
                    type="submit"
                    title="Send"
                    className="gt-btn-grad-primary"
                    isLoading={loading}
                  />
                </div> 

                <div className="mt-3 clr-gray-dark fs-20">
                  Remember It ? {""}
                  <Link to={APP_ROUTES.LOGIN} className="clr-blue">
                    Sign In here
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      {/* </Row> */}
    </Container>
    // <div className="account-pages my-5 pt-sm-5">
    //   <Container>
    //     <Row className="justify-content-center">
    //       <Col md={8} lg={6} xl={5}>
    //         <Card className="overflow-hidden gt-auth-card">
    //           <div className="gt-card-head-bgimg">
    //             <Row>
    //               <Col xs={7}>
    //                 <div className="text-primary p-4">
    //                   <h5 className="clr-blue">Reset Password</h5>
    //                   <h6 className="clr-blue fw-normal">
    //                     Re-Password with GYFT.
    //                   </h6>
    //                 </div>
    //               </Col>
    //               <Col className="col-5 align-self-end">
    //                 <img src={profile} alt="" className="img-fluid" />
    //               </Col>
    //             </Row>
    //           </div>
    //           <CardBody className="pt-0 bg-clr-body">
    //             <div>
    //               <Link to={APP_ROUTES.LOGIN}>
    //                 <div className="avatar-md profile-user-wid mb-4">
    //                   <span className="avatar-title rounded-circle bg-light">
    //                     <img src={logo} alt="Gyft" className="rounded-circle" />
    //                   </span>
    //                 </div>
    //               </Link>
    //             </div>
    //             <div className="p-2">
    //               <Alert
    //                 color="success"
    //                 text="Enter your Email and instructions will be sent to you!"
    //               />
    //               <Form
    //                 className="form-horizontal"
    //                 onSubmit={e => {
    //                   e.preventDefault()
    //                   validation.handleSubmit()
    //                   return false
    //                 }}
    //               >
    //                 <div className="mb-3">
    //                   <TextInput
    //                     label="Email"
    //                     name="email"
    //                     placeholder="Enter email"
    //                     type="email"
    //                     onChange={validation.handleChange}
    //                     onBlur={validation.handleBlur}
    //                     value={validation.values.email || ""}
    //                     invalid={
    //                       validation.touched.email && !!validation.errors.email
    //                     }
    //                     valid={
    //                       validation.touched.email && !validation.errors.email
    //                     }
    //                   />
    //                   {validation.touched.email &&
    //                     !!validation.errors.email && (
    //                       <div className="form-input-error">
    //                         {validation.errors.email}
    //                       </div>
    //                     )}
    //                 </div>
    //                 <Row className="mb-3">
    //                   <Col className="text-end">
    //                     <Button
    //                       size="sm"
    //                       type="submit"
    //                       title="Send"
    //                       className="gt-btn-grad-primary"
    //                       isLoading={loading}
    //                     />
    //                   </Col>
    //                 </Row>
    //               </Form>
    //             </div>
    //           </CardBody>
    //         </Card>
    //         <div className="mt-5 text-center clr-gray-dark fs-13">
    //           Remember It ? {""}
    //           <Link to={APP_ROUTES.LOGIN} className="clr-blue">
    //             Sign In here
    //           </Link>
    //         </div>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
  )
}

export default withAuthentication(withRouter(ForgetPassword))

import React from "react"

const ToggleBtn = props => {
  const {
    checked = false,
    value = null,
    onClick = () => {},
    className = "",
  } = props

  return (
    <div className={`gt-toggle-switch-btn d-flex ${className}`}>
      <input
        className={checked ? "switched" : ""}
        type="checkbox"
        checked={checked}
        onClick={onClick}
        id="switch-toggle"
        name="switch-toggle"
      />
      {/* Use HtmlFor instead of For. */}
      <label htmlFor="switch-toggle"></label>
    </div>
  )
}
export default ToggleBtn

import React, { useState, useEffect } from "react"
import toast from "react-hot-toast"

import { DataTable } from "components/DataTable"
import { TextInput } from "components/TextInput"
import { AutoComplete } from "components/AutoComplete"
import { Button } from "components/Button"
import {
  GetDepartmentInventoryListing,
  CreatePullbackRequest,
} from "api/api.service"
import DeleteIcon from "assets/images/inventory/delete-icon.png"
import Config from "Config"
import { validateOptionSelect, makeOptionsDisabled } from "utils/commonUtils"

const COLUMNS = {
  SKU_NO: "SKU No.",
  ITEM_NAME: "Item Name",
  DEPARTMENT_NAME: "Department Name",
  REQUIRED_QUANTITY: "Required Quantity",
  AVAILABLE_QUANTITY: "Available Quantity",
  ACTION: "Action",
}

const handleGetInventoryListing = ({
  modalCloseHandler = () => {},
  selectedData = [],
  params = null,
}) => {
  let controller = new AbortController()
  let signal = controller.signal

  const [isAddingRequest, setIsAddingRequest] = useState()
  const [selectedItems, setSelectedItems] = useState(null)
  const [previousSelectedItems, setPreviousSelectedItems] = useState([
    ...selectedData,
  ])

  const [inventoryListing, setInventoryListing] = useState({
    data: [],
    fetching: true,
    page: 1,
    isDataAvailable: true,
  })

  useEffect(() => {
    inventoryListing.page && handleGetInventoryListing(inventoryListing.page)

    return () => {
      controller.abort()
    }
  }, [inventoryListing.Q])

  const handleSelectOption = option => {
    if (
      typeof option != "object" ||
      !Object.keys(option) ||
      validateOptionSelect(option, previousSelectedItems, "id")
    )
      return
    setPreviousSelectedItems(prev => {
      return [...prev, { ...option, requiredQuantity: "" }]
    })
  }
  const handleSearch = (value = "", callback) => {
    if (!value) return

    callback(prev => {
      return {
        ...prev,
        data: [],
        page: 1,
        Q: value,
      }
    })
  }

  const handleGetInventoryListing = page => {
    let itemsIdArr, test
    if (previousSelectedItems.length) {
      itemsIdArr = previousSelectedItems?.map(item => item.itemsId)
    }

    setInventoryListing(prev => {
      return {
        ...prev,
        fetching: true,
      }
    })
    GetDepartmentInventoryListing(
      {
        Limit: Config.LIMIT,
        ...(inventoryListing.Q && { Q: inventoryListing.Q }),
        Page: page,
        ...(params && params),
      },
      signal
    )
      .then(res => {
        setInventoryListing(prev => ({
          ...prev,
          fetching: false,
          page: page + 1,
          isDataAvailable: res?.data?.length == Config.LIMIT,
          data: (() => {
            test = res?.data
              ?.map(item => ({
                ...item,
                value: item.id,
                label: item.itemName,
              }))
              ?.filter(item => Math.abs(item.committed - item?.inStock) > 0)

            if (previousSelectedItems.length) {
              test = test.filter(item => !itemsIdArr.includes(item.id))
            }

            return [...test]
          })(),
        }))
      })
      .finally(() => {
        setInventoryListing(prev => {
          return {
            ...prev,
            fetching: false,
          }
        })
      })
  }

  const handleSetItemQuantity = (evt, targetObj, index) => {
    let indexOfTargetObj = index
    let stateCopy = [...previousSelectedItems]
    stateCopy[indexOfTargetObj] = {
      ...targetObj,
    }
    let value = Math.abs(evt.target.value)
    let limit = Math.abs(targetObj?.committed - targetObj?.inStock)

    if (value > limit || value.toString().includes("-")) {
      return
    }

    stateCopy[indexOfTargetObj] = {
      ...targetObj,
      requiredQuantity: value,
    }
    setPreviousSelectedItems(stateCopy)
  }

  const handleDeleteSelectedItem = (id = "", sku) => {
    if (!id) return

    let filteredData = previousSelectedItems.filter(obj => obj.id != id)
    setPreviousSelectedItems([...filteredData])
    if (filteredData.length < 1) {
      setSelectedItems(null)
    }

    inventoryListing?.data.map(el => {
      if(el.skuNo === sku){
        el.isDisabled = false
      }
    })

  }

  const handleConfirmRequest = async () => {
    if (
      previousSelectedItems
        .map(item => {
          return Math.abs(item.requiredQuantity)
        })
        .includes(0)
    ) {
      toast.error("Required quantity can't be 0")
      return
    }

    let formattedItemsList = previousSelectedItems.map(obj => {
      return {
        itemsId: obj.itemsId || obj.id,
        quantity: obj.requiredQuantity,
        departmentId: obj?.department?.id,
      }
    })

    let bodyData = {
      datas: formattedItemsList,
    }

    try {
      setIsAddingRequest(true)
      let res = await CreatePullbackRequest(bodyData)
      setSelectedItems("")
      setPreviousSelectedItems("")
      setInventoryListing([])
      modalCloseHandler()
      toast.success("Request has been created successfully !")
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsAddingRequest(false)
    }
  }

  return (
    <>
      <h3 className="text-center inv-req-popup-heading">Add New Request</h3>
      <div className="pt-3 pb-4 add-new-req-select-con">
        <span className="font-size-13">Add Items : </span>
        <AutoComplete
          placeholder="Search Item"
          classNamePrefix="add-new-req"
          value={selectedItems}
          handleBlur={() =>
            setInventoryListing(prev => {
              return {
                ...prev,
                page: 1,
                Q: "",
              }
            })
          }
          onInputChange={val => handleSearch(val, setInventoryListing)}
          options={makeOptionsDisabled(previousSelectedItems, inventoryListing)}
          onChange={val => handleSelectOption(val)}
          closeMenuOnSelect={true}
          isLoading={inventoryListing?.fetching}
        />
      </div>
      <DataTable
        data={previousSelectedItems}
        className="view-detail-inv-req-table add-req-inv-con height-scroll-table"
        tableClasses="add-req-inv-table"
        config={[
          {
            title: COLUMNS.SKU_NO,
            render: data => {
              return data?.skuNo
            },
          },
          {
            title: COLUMNS.ITEM_NAME,
            render: data => {
              return data?.itemName
            },
          },
          {
            title: COLUMNS.DEPARTMENT_NAME,
            render: data => {
              return data?.department?.departmentName
            },
          },
          {
            title: COLUMNS.AVAILABLE_QUANTITY,
            render: data => {
              return data?.inStock - data?.committed
            },
          },
          {
            title: COLUMNS.REQUIRED_QUANTITY,
            render: (data, idx) => {
              return (
                <div className="text-right">
                  <TextInput
                    onChange={evt => handleSetItemQuantity(evt, data, idx)}
                    className="view-detail-inv-req-p-ip-con me-4 me-md-5"
                    labelClass="d-none"
                    type="number"
                    min={1}
                    inputClass="view-detail-inv-req-p-ip"
                    placeholder={"0"}
                    value={data.requiredQuantity}
                  />
                </div>
              )
            },
          },

          {
            title: COLUMNS.ACTION,
            render: data => {
              return (
                <img
                  onClick={() => handleDeleteSelectedItem(data.id, data.skuNo)}
                  className="view-detail-modal-delete"
                  src={DeleteIcon}
                />
              )
            },
          },
        ]}
      />
      <div className="text-center my-4">
        <Button
          disabled={!Boolean(previousSelectedItems?.length)}
          isLoading={isAddingRequest}
          size="lg"
          className="gt-btn-grad-primary table-bottom-btn m-0 p-0 btn-alignment"
          title="Confirm Request"
          onClick={handleConfirmRequest}
        />
      </div>
    </>
  )
}

export default handleGetInventoryListing

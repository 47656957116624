import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// users
import avatarPalceholerImg from "assets/images/users/avatar-paceholder.png"
import { logout, SIGN_OUT } from "store/actions"
import { APP_ROUTES } from "helpers/routeHelpers"
import { getMediaPath } from "utils/mediaUtils"

const ProfileMenu = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  // Added modal support to be use in future for casita
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const user = useSelector(state => state.userAuth?.user)

  const handleLogoutModal = () => {
    setShowLogoutModal(true)
  }

  const handleLogout = () => {
    // try {
    //   dispatch(logout())
    // } catch (err) {
    // } finally {
    //   localStorage.clear()
    //   dispatch({ type: SIGN_OUT })
    // }

    localStorage.clear()
    dispatch({ type: SIGN_OUT })
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={getMediaPath(user?.Media) || avatarPalceholerImg}
            alt=""
          />

          <span className="d-xl-inline-block ms-2 me-1">
            {user?.firstName + " " + user?.lastName || "User"}
          </span>
          <i className="bx bx-chevron-down d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem> */}
          {/* <div className="dropdown-divider" /> */}

          <div className="dropdown-item pointer" onClick={handleLogout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
}

const mapStatetoProps = state => {
  const profile = { success: true, error: false }
  const { error, success } = profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)

import React from "react"
import { Container, Row, Col } from "reactstrap"

import { IconCardLink } from "components/IconCardLink"
import { TableTopHeading } from "components/TableTopHeading"
import inventoryIcon from "assets/images/icons/inventory-icon.png"
import inventoryRequestIcon from "assets/images/icons/inventory-request-icon.png"
import settingsIcon from "assets/images/icons/settings-icon.png"
import scrapReturnIcon from "assets/images/icons/scrap-return-icon.png"
import dashboardUserIcon from "assets/images/icons/user-icon.png"
import { APP_ROUTES } from "helpers/routeHelpers"
import { getAppName } from "utils/commonUtils"

const config = [
  {
    menuName: "Inventory",
    menuRoute: APP_ROUTES.INVENTORY,
    menuIcon: inventoryIcon,
  },
  {
    menuName: "Inventory Requests",
    menuRoute: "/inventory-request",
    menuIcon: inventoryRequestIcon,
  },
  {
    menuName: "Settings",
    menuRoute: APP_ROUTES.SETTINGS,
    menuIcon: settingsIcon,
  },
  {
    menuName: "Scrap / Return",
    menuRoute: APP_ROUTES.SCRAP_RETURN,
    menuIcon: scrapReturnIcon,
  },
]

const Dashboard = ({}) => {
  return (
    <>
      <div className="casita-department-dashboard">
        <Container fluid>
          <div className="pb-3">
            <TableTopHeading
              title={`Dashboard (${getAppName()})`}
              iconImage={dashboardUserIcon}
            />
          </div>

          <Row className="align-items-center">
            <Col>
              <div className="dashboard-boxes">
                {config.map((item, idx) => {
                  return (
                    <IconCardLink
                      image={item.menuIcon}
                      text={item.menuName}
                      key={idx}
                      url={item.menuRoute}
                    />
                  )
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Dashboard

import { injectBaseConstantMethods } from "./BaseConstants"

const InventoryReqItemStatus = {
  Pending: "Pending",
  All_Delivered: "AllDelivered",
  Partial_Delivered: "PartialDelivered",
  Substitutes: "Substitutes",
}

const displayTextKeys = {
  [InventoryReqItemStatus.Pending]: "Pending",
  [InventoryReqItemStatus.All_Delivered]: "All Delivered",
  [InventoryReqItemStatus.Partial_Delivered]: "Partial Delivered",
  [InventoryReqItemStatus.Substitutes]: "Substitutes",
}

const labelClass = {
  [InventoryReqItemStatus.Pending]: "bg-red",
  [InventoryReqItemStatus.All_Delivered]: "bg-green",
  [InventoryReqItemStatus.Partial_Delivered]: "bg-orange",
  [InventoryReqItemStatus.Substitutes]: "bg-orange",
}

export default injectBaseConstantMethods(
  InventoryReqItemStatus,
  displayTextKeys,
  labelClass
)

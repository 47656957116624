export default {
  DASHBOARD: "Dashboard",
  LOGIN: "Login",
  FORGET_PASSWORD: "Forgot Password",
  FORGET_VERIFY_CODE: "Forget Verification Code",
  RESET_PASSWORD: "Reset Password",
  SETTINGS: "Settings",
  USER_PROFILE: "User Profile",
  DATA_TABLE: "Data Table",
  SETTINGS: "Settings",
  INVENTORY: "Inventory",
  INVENTORY_REQUEST: "Inventory Request",
  SCRAP_RETURN: "Scrap/Return",
  ORDER_MANAGEMENT: "Order Management",
}

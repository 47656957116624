import React, { useEffect, useState } from "react"
import {
  Card as ReactStrapCard,
  CardBody,
  Container,
  Label,
  FormGroup,
  Col,
  Form,
  Row,
} from "reactstrap"

import { ToggleBtn } from "components/ToggleBtn"
import { TextInput } from "components/TextInput"
import { BackButtonTableTop } from "components/BackButtonTableTop"
import { TableTopHeading } from "components/TableTopHeading"
import settingsIcon from "../../assets/images/icons/setting-nav-icon.png"
import { Button } from "components/Button"
import { GetMe, notificationSetting, passwordSetting } from "api/api.service"
import { toast } from "react-hot-toast"
import { Loader } from "components/Loader"
import { useFormik } from "formik"
import { initialValues, validationSchema } from "./FormConfig"

const Settings = ({}) => {
  const [notificationState, setNoticationState] = useState()
  const [loading, setLoading] = useState(false)
  const [buttonloading, setButtonLoading] = useState(false)
  const [notificationLoading, setNotificationLoading] = useState(false)

  const handleSetting = e => {
    console.log(setOldPassword)
  }

  let getNoticationSetting = async () => {
    try {
      setLoading(true)
      let res = await GetMe()
      setNoticationState(res.notificationSetting)
    } catch (err) {
      console.log(err.message)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getNoticationSetting()
  }, [])

  let handleNotificationSetting = async () => {
    try {
      let body = {
        notificationSetting: !notificationState,
      }
      setNotificationLoading(true)
      let res = await notificationSetting(body)
      setNoticationState(prev => !prev)
      toast.success("Notfication Setting has been changed !")
    } catch (err) {
      toast.error(err.message)
    } finally {
      setNotificationLoading(false)
    }
  }

  let handlePasswordSetting = async data => {
    try {
      let payload = {
        CurrentPassword: data?.CurrentPassword,
        NewPassword: data?.NewPassword,
      }
      setButtonLoading(true)
      let res = await passwordSetting(payload)
      updatedFieldValue()
      toast.success("Password changed Successfully !")
    } catch (err) {
      toast.error(err.message)
    } finally {
      setButtonLoading(false)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      handlePasswordSetting(values)
    },
  })

  const updatedFieldValue = () => {
    formik.setFieldValue("CurrentPassword", "")
    formik.setFieldValue("NewPassword", "")
    formik.setFieldValue("confirmNewPassword", "")
  }

  return (
     <React.Fragment>
      <div className="gt-business-accounts px-3 px-md-5">
        <Container fluid>
          <Row>
            <Col className="col-12 px-lg-3 px-2 pt-3">
              <div className="d-flex align-items-center mb-4">
                <BackButtonTableTop />
              </div>
              <div className="settings-header-con">
                <TableTopHeading
                  // className="settings-header"
                  title="SETTINGS"
                  iconImage={settingsIcon}
                  iconClass="setting-icon"
                />
              </div>
              <Form onSubmit={formik.handleSubmit}>
                <ReactStrapCard className="card-container mx-0">
                  {loading ? (
                    <div className="loader-handle">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <FormGroup row>
                        <Label for="notifications" sm={4} className="label-Container ">
                          Notifications :
                        </Label>
                        <Col>
                          {notificationLoading ? (
                            <Col sm={2} md={2}>
                              <Loader />
                            </Col>
                          ) : (
                            <ToggleBtn
                              checked={notificationState}
                              onClick={handleNotificationSetting}
                            />
                          )}
                        </Col>
                      </FormGroup>

                      <FormGroup className=" user-validation-handler" row>
                        <Label
                          for="old Password"
                          sm={4}
                          className="label-Container user-validation-handler"
                        >
                          Old Password :
                        </Label>
                        <Col xs={10} sm={3} md={4}>
                          <TextInput
                            className="text-input "
                            inputClass="settings-ip-fields"
                            type="Current Password"
                            name="CurrentPassword"
                            id="Current Password"
                            placeholder=""
                            value={formik.values.CurrentPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />

                          <div className="input-validation-container">
                            {formik.touched.CurrentPassword &&
                            formik.errors.CurrentPassword ? (
                              <span className="error fs-10">
                                {formik.errors.CurrentPassword}
                              </span>
                            ) : null}
                          </div>
                        </Col>
                      </FormGroup>

                      <FormGroup className=" user-validation-handler" row>
                        <Label for="new password" sm={4} className="label-Container ">
                          New Password :
                        </Label>
                        <Col xs={10} sm={3} md={4}>
                          <TextInput
                            className="text-input"
                            inputClass="settings-ip-fields"
                            type="new password"
                            name="NewPassword"
                            id="new password"
                            placeholder=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.NewPassword}
                          />

                          <div className="input-validation-container">
                            {formik.touched.NewPassword && formik.errors.NewPassword ? (
                              <span className="error fs-10">
                                {formik.errors.NewPassword}
                              </span>
                            ) : null}
                          </div>
                        </Col>
                      </FormGroup>

                      <FormGroup className=" user-validation-handler" row>
                        <Label
                          for="confirm new password"
                          sm={4}
                          className="label-Container "
                        >
                          Confirm New Password :
                        </Label>
                        <Col sm={3} md={4} xs={10}>
                          <TextInput
                            className="text-input"
                            inputClass="settings-ip-fields"
                            type="confirm new password"
                            name="confirmNewPassword"
                            id="confirm new password"
                            placeholder=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmNewPassword}
                          />

                          {formik.touched.confirmNewPassword &&
                          formik.errors.confirmNewPassword ? (
                            <span className="error fs-10">
                              {formik.errors.confirmNewPassword}
                            </span>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </>
                  )}
                </ReactStrapCard>
                {!loading && (
                  <div className="text-center button-container-width mt-4">
                    <Button
                      isLoading={buttonloading}
                      type="submit"
                      size="lg"
                      className="gt-btn-grad-primary table-bottom-btn btn-alignment"
                      title={<div>Save</div>}
                    />
                  </div>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Settings

import React, { useState, useEffect } from "react"
import { Col, Row, Form, FormGroup, Label, Input, FormText } from "reactstrap"
import { toast } from "react-hot-toast"

import ScrapImageUpload from "./ScrapImageUpload"
import dumyimage from "assets/images/image-upload-placeholder.png"
import { ImageTypes } from "../../../constants"
import { AutoComplete } from "components/AutoComplete"
import { Loader } from "components/Loader"
import { Button, GroupedButtons } from "components/Button"
import { AutoCompleteStylingScrapReturn } from "../AutoCompleteStyling"
import {
  finalizeMediaUtil,
  getMediaPath,
  initializeMediaUtil,
  uploadOnS3,
} from "utils/mediaUtils"
import Config from "Config"
import { isMinMaxValidate, preventUnwantedInput } from "utils/formUtils"

const MAX_IMG_SIZE = 5120
const GROUPED_BTNS_CONFIG = [
  {
    color: "primary",
    isOutlined: true,
    activeId: "Scrap",
    classes: "btn-common",
    btnTitle: "Scrap",
    onClick: () => {},
  },
  {
    color: "primary",
    isOutlined: true,
    activeId: "Return",
    classes: "btn-common",
    btnTitle: "Return",
    onClick: () => {},
  },
]

const ScrapReturnForm = ({
  apiFunction = null,
  isReadOnly = true,
  isSubmitting = false,
  params = null,
  quantityKey = "",
  data = null,
  onSubmit = () => {},
  closeModal = () => {},
}) => {
  let controller = new AbortController()
  let signal = controller.signal

  const [image, setImage] = useState("")
  const [imagePreview, setImagePreview] = useState(dumyimage)
  const [imageUploaded, setImageUploaded] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [reqType, setReqType] = useState(null)
  const [sku, setSku] = useState("")
  const [itemName, setItemName] = useState("")
  const [quantity, setQuantity] = useState("")
  const [quantityLimit, setQuantityLimit] = useState("")
  const [issue, setIssue] = useState("")
  const [inventory, setInventory] = useState({
    data: [],
    fetching: false,
    page: 1,
    isDataAvailable: true,
    Q: "",
  })

  useEffect(() => {
    typeof apiFunction == "function" &&
      inventory.page &&
      handleGetInventory(inventory.page)

    return () => {
      controller.abort()
      setImagePreview(dumyimage)
    }
  }, [inventory.Q])

  useEffect(() => {
    if (data) {
      setIssue(data?.issue)
      setItemName(data?.itemName)
      setQuantity(data?.quantity)
      setSku(data?.skuNo)
      setReqType(data?.type)
      setImagePreview(getMediaPath(data?.image))
    }
  }, [data])

  const handleSelectOption = option => {
    if (typeof option != "object" || !Object.keys(option)) return
    setSku(option)
    setItemName(option.itemName)
    setQuantityLimit(option?.committed || option?.quantity)
  }

  const handleSearch = (value = "", callback) => {
    if (!value) return

    callback(prev => {
      return {
        ...prev,
        data: [],
        page: 1,
        Q: value,
      }
    })
  }

  const handleGetInventory = page => {
    let test
    setInventory(prev => {
      return {
        ...prev,
        fetching: true,
      }
    })
    apiFunction(
      {
        Limit: Config.LIMIT,
        ...(inventory.Q && { Q: inventory.Q }),
        ...(params && params),
        Page: page,
      },
      signal
    )
      .then(res => {
        setInventory(prev => ({
          ...prev,
          fetching: false,
          page: page + 1,
          isDataAvailable: res?.data?.length == Config.LIMIT,
          data: (() => {
            test = res?.data
              ?.map(item => ({
                ...item,
                value: item.skuNo,
                label: item.skuNo,
              }))
              ?.filter(item => {
                return item[quantityKey] > 0
              })

            return [...test]
          })(),
        }))
      })
      .finally(() => {
        setInventory(prev => {
          return {
            ...prev,
            fetching: false,
          }
        })
      })
  }

  const handleFileChange = e => {
    let file = e.target.files[0]
    if (file) {
      const uploadedFileType = file?.type?.split("/")[1]?.toLowerCase()
      let fileSize = file && file?.size
      fileSize = fileSize / MAX_IMG_SIZE

      if (file && ImageTypes.includes(uploadedFileType)) {
        let reader = new FileReader()
        reader.onload = e => {
          let imagePreview = e.target?.result
          setImage(file)
          setImagePreview(imagePreview)
        }
        reader.readAsDataURL(file)
        setImageUploaded(true)
      } else {
        setImageUploaded(false)
        setImage("")
        setImagePreview("")
      }
    }
  }

  const getContent = value => {
    if (isReadOnly && value) {
      return value
    } else if (isReadOnly && !value) {
      return "-"
    } else {
      return false
    }
  }

  const handleSetQuantity = evt => {
    let value = evt.target.value

    if (!isMinMaxValidate(value, 1, quantityLimit)) {
      // toast.error("Limit exceed")
      return
    }
    setQuantity(value)
  }

  const handleMediaUpload = async file => {
    return initializeMediaUtil(file).then(async res => {
      const credentials = res
      await uploadOnS3(file, credentials)
      return await finalizeMediaUtil(credentials?.mediaId)
    })
  }

  const handleSubmit = async () => {
    if (!reqType) {
      toast.error("Select request type i.e Scrap / Return")
      return
    } else if (quantity < 1) {
      toast.error("Enter quantity")
      return
    } else if (!image) {
      toast.error("Scrap/Return image is required !")
      return
    }
    let payload = {
      itemsId: sku.itemsId,
      quantity: Math.abs(quantity),
      ...(issue && { issue: issue }),
      requestType: reqType,
    }

    if (image) {
      try {
        setIsLoading(true)
        let imageRes = await handleMediaUpload(image)
        payload.imageId = imageRes.id
        onSubmit(payload)
      } catch (err) {
        toast.error(err.message)
      } finally {
        setIsLoading(false)
      }
    } else {
      onSubmit(payload)
    }
  }

  const handleRemoveImage = () => {
    setImagePreview(dumyimage)
    setImage("")
    setImageUploaded(false)
  }

  return (
    <>
      {inventory.fetching && isReadOnly ? (
        <Loader />
      ) : (
        <div>
          <h3 className="text-center">SCRAP / RETURN</h3>
          <div className="text-center mt-4 mb-4">
            <GroupedButtons
              isReadOnly={isReadOnly}
              handleSetActive={val => setReqType(val)}
              defaultActive={reqType}
              btnConfig={GROUPED_BTNS_CONFIG}
              btnContainerClasses="scrap-return-button-container"
            />
          </div>
          <Row>
            <Col sm="12" md="6" lg="6">
              <Form>
                <FormGroup row>
                  <Label
                    for="skuSelection"
                    sm={isReadOnly ? 6 : 2}
                    md={4}
                    align="right"
                    className="pl-0 pr-0 pe-1 pe-lg-2"
                  >
                    SKU No.
                  </Label>

                  <Col sm={isReadOnly ? 6 : 10} md={8} className="pl-0 pr-0 d-flex align-items-center">
                    {getContent(sku) || (
                      <AutoComplete
                        placeholder="Search Item"
                        classNamePrefix="add-new-req"
                        customStyles={AutoCompleteStylingScrapReturn}
                        value={sku}
                        // customStyles={AddNewRequestSelect}
                        handleBlur={() =>
                          setInventory(prev => {
                            return {
                              ...prev,
                              page: 1,
                              Q: "",
                            }
                          })
                        }
                        onInputChange={val => handleSearch(val, setInventory)}
                        options={inventory?.data}
                        onChange={val => handleSelectOption(val)}
                        closeMenuOnSelect={true}
                        isLoading={inventory?.fetching}
                        hideSelectedOptions
                      />
                    )}
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="itemName" sm={isReadOnly ? 6 : 2}
                    md={4} align="right" className="ps-0 pe-1 pe-lg-2">
                    Item Name
                  </Label>
                  <Col sm={isReadOnly ? 6 : 10} md={8} className="d-flex align-items-center">
                    {getContent(itemName) || (
                      <Input
                        type="text"
                        name="Item Name"
                        id="itemName"
                        value={itemName}
                        disabled
                      />
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="quantity" sm={isReadOnly ? 6 : 2}
                    md={4} align="right" className="pe-1 pe-lg-2">
                    Quantity
                  </Label>
                  <Col
                    sm={isReadOnly ? 6 : 10} md={8}
                    className="d-flex justify-content-center flex-column"
                  >
                    {getContent(quantity) || (
                      <Input
                        onKeyDown={e => preventUnwantedInput(e)}
                        disabled={!!!sku}
                        type="number"
                        name="Quantity"
                        id="quantity"
                        value={quantity}
                        onChange={e => handleSetQuantity(e)}
                        max={quantityLimit}
                      />
                    )}

                    {quantityLimit && (
                      <FormText>
                        Can't be exceed more than {quantityLimit}.
                      </FormText>
                    )}
                  </Col>
                </FormGroup>
                {/* Issue */}
                <FormGroup row>
                  <Label for="issues" sm={isReadOnly ? 6 : 2}
                    md={4} align="right" className="pe-1 pe-lg-2">
                    Issue
                  </Label>
                  <Col sm={isReadOnly ? 6 : 10} md={8} className="d-flex align-items-center">
                    {getContent(issue) || (
                      <Input
                        disabled={!!!sku}
                        type="textarea"
                        name="Issue"
                        id="issues"
                        value={issue}
                        onChange={e => setIssue(e.target.value)}
                      />
                    )}
                  </Col>
                </FormGroup>
              </Form>
            </Col>

            <Col sm="12" md="6" lg="6">
              <ScrapImageUpload
                image={imagePreview}
                isReadOnly={isReadOnly}
                onChange={handleFileChange}
                isNewImageSet={imageUploaded}
                setImageUploaded={setImageUploaded}
                dumyimage={dumyimage}
                removeImageHandler={handleRemoveImage}
              />
            </Col>
          </Row>

          <div className="mt-3 text-center">
            <Button
              disabled={!!!sku}
              type={isReadOnly ? "" : "submit"}
              title={isReadOnly ? "Done" : "Confirm Request"}
              className="gt-btn-grad-primary btn-alignment"
              isLoading={isSubmitting || isLoading}
              onClick={isReadOnly ? closeModal : handleSubmit}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ScrapReturnForm

import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import { Row, Col, Input, Label, Form, FormGroup } from "reactstrap"

import { Button } from "components/Button"
import { ItemCategoriesListing } from "api/api.service"
import { AutoCompleteStyling } from "../../Common/AutoCompleteStyling"
import { AutoComplete } from "components/AutoComplete"
import { initialValues, validationSchema } from "./FormConfig"
import ItemThresholdSection from "./ItemThresholdSection"
import Config from "Config"

const ITEM_TYPE = {
  ["General Inventory"]: "General",
  ["Chemicals"]: "Chemicals",
  ["Tools"]: "Tools",
}

const AddNewItemForm = ({
  data = "", 
  title = "",
  subTitle = "",
  footerBtnType = "",
  modalCloseHandler = () => {},
  handleFormSubmit = () => {},
  isLoading = false,
  isReadOnly = false,
}) => {
  let controller = new AbortController()
  let signal = controller.signal
  const [selectedtemType, setSelectedtemType] = useState(null)
  const [categorySelectedValue, setCategorySelectedValue] = useState(null)
  const [categoryListing, setCategoryListing] = useState({
    data: [],
    fetching: false,
    page: 1,
    Q: "",
    isDataAvailable: false,
  })
  useEffect(() => {
    if (isReadOnly) {
      viewDetailsData(data)
    } else {
      handleGetCategories(categoryListing.page)
      viewDetailsData(data)
    }

    return () => {
      controller.abort()
    }
  }, [categoryListing.Q])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      handleFormSubmit(values)
    },
  })

  const handleCategorySearch = (value, callback) => {
    if (!value) return

    callback(prev => {
      return {
        ...prev,
        data: [],
        page: 1,
        Q: value,
      }
    })
  }

  const handleGetCategories = page => {
    handleStateSet(setCategoryListing, { fetching: true, data: [] })
    ItemCategoriesListing(
      {
        limit: Config.LIMIT,
        ...(categoryListing.Q && { Q: categoryListing.Q }),
        page: page,
      },
      signal
    )
      .then(res => {
        setCategoryListing(prev => ({
          ...prev,
          fetching: false,
          page: page + 1,
          isDataAvailable: res?.data.length === Config.LIMIT,
          data: (() => {
            let test = res?.data.map(item => ({
              ...item,
              value: item?.id,
              label: item?.name,
            }))
            return [...test, ...prev.data]
          })(),
        }))
      })
      .finally(() => {
        handleStateSet(setCategoryListing, { fetching: false })
      })
  }

  const handleStateSet = (callback = () => {}, keysObj = {}) => {
    callback(prev => {
      return {
        ...prev,
        ...(keysObj && keysObj),
      }
    })
  }

  const handleSelectCategory = option => {
    if (categorySelectedValue && categorySelectedValue.id == option.id) return

    formik.setFieldValue("itemsCategoryId", option.value)
    setCategorySelectedValue(option)
  }

  const viewDetailsData = data => {
    if (!data && subTitle) {
      formik.setFieldValue("itemType", ITEM_TYPE[subTitle])
      return
    }

    if (data) {
      setCategorySelectedValue({
        label: data?.itemCategoryName,
        value: data?.itemsCategoryId,
      })
      setSelectedtemType({
        label: data?.itemType,
        value: data?.itemType,
      })
      formik.setFieldValue("id", data?.id)
      formik.setFieldValue("skuNo", data?.skuNo)
      formik.setFieldValue("itemName", data?.itemName)
      formik.setFieldValue("description", data?.description)
      formik.setFieldValue("majorThreshold", data?.majorThreshold)
      formik.setFieldValue("minorThreshold", data?.minorThreshold)
      formik.setFieldValue("mediumThreshold", data?.mediumThreshold)
      formik.setFieldValue("itemsCategoryId", data?.itemsCategoryId)
      formik.setFieldValue("itemType", data?.itemType)
    }

  }
  const handleSelectOption = option => {
    setSelectedtemType(option)
    formik.setFieldValue("itemType", option !== null ? option.value : "")
  }

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <h3 className="text-center inv-req-popup-heading mb-0">{title}</h3>
        <h5 className="text-center inv-req-popup-heading wh-im-dpgi-addnewitem">
          {subTitle}
        </h5>
        <div className="pt-3 px-4">
          <Row>
            <Col sm="12" xs="12" md="6" lg="6">
              <FormGroup row className="align-items-center">
                <Label
                  for="exampleSelect"
                  sm={2}
                  md={3}
                  align="right"
                  className="pl-0 pr-0 px-0 addnewitem-typo"
                >
                  Item
                </Label>

                <Col sm={9} className="pl-0 pr-0">
                  <Input
                    disabled={isReadOnly}
                    type="text"
                    name="itemName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.itemName}
                    id="exampleSelect"
                  />
                  {formik.touched.itemName && formik.errors.itemName ? (
                    <span className="error fs-10">
                      {formik.errors.itemName}
                    </span>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
            <Col sm="12" xs="12" md="6" lg="6">
              <FormGroup row className="align-items-center">
                <Label
                  for="exampleSelect"
                  sm={2}
                  md={3}
                  align="right"
                  className="pl-0 pr-0 px-0 sm-2 addnewitem-typo"
                >
                  Category
                </Label>

                <Col sm={9} className="pl-0 pr-0 ">
                  <AutoComplete
                    isDisabled={isReadOnly}
                    handleBlur={() =>
                      setCategoryListing(prev => {
                        return {
                          ...prev,
                          page: 1,
                          Q: "",
                        }
                      })
                    }
                    onChange={handleSelectCategory}
                    onInputChange={val =>
                      handleCategorySearch(val, setCategoryListing)
                    }
                    placeholder="Search Category"
                    classNamePrefix="add-new-item"
                    customStyles={AutoCompleteStyling}
                    options={categoryListing?.data}
                    isClearable={true}
                    value={categorySelectedValue}
                    isLoading={categoryListing?.fetching}
                  />
                  {formik.touched.itemsCategoryId &&
                  formik.errors.itemsCategoryId ? (
                    <span className="error fs-10">
                      {formik.errors.itemsCategoryId}
                    </span>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
            <Col sm="12" xs="12" md="6" lg="6">
              <FormGroup row className="align-items-center">
                <Label
                  for="exampleEmail"
                  sm={2}
                  md={3}
                  align="right"
                  className="px-0 addnewitem-typo"
                >
                  SKU. No.
                </Label>
                <Col sm={9}>
                  <Input
                    disabled={isReadOnly}
                    type="text"
                    name="skuNo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    value={formik.values.skuNo}
                    id="exampleEmail"
                  />
                  {formik.touched.skuNo && formik.errors.skuNo ? (
                    <span className="error fs-10">{formik.errors.skuNo}</span>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
            {!subTitle && (
              <Col sm="12" xs="12" md="6" lg="6">
                <FormGroup row className="align-items-center">
                  <Label
                    for="exampleEmail"
                    sm={2}
                    md={3}
                    align="right"
                    className="px-0 addnewitem-typo"
                  >
                    Item Type
                  </Label>
                  <Col sm={9}>
                    <AutoComplete
                      isDisabled={isReadOnly}
                      value={selectedtemType}
                      isClearable
                      onChange={val => handleSelectOption(val)}
                      handleBlur={formik.handleBlur}
                      isSearchable={false}
                      placeholder="Select Item type"
                      customStyles={AutoCompleteStyling}
                      classNamePrefix="add-new-item"
                      options={[
                        {
                          label: "General",
                          value: "General",
                        },
                        {
                          label: "Chemicals",
                          value: "Chemicals",
                        },
                        {
                          label: "Tools",
                          value: "Tools",
                        },
                      ]}
                    />
                    {formik.touched.itemType && formik.errors.itemType ? (
                      <span className="error fs-10">
                        {formik.errors.itemType}
                      </span>
                    ) : null}
                  </Col>
                </FormGroup>
              </Col>
            )}
            <Col sm="12" xs="12" md="12" lg="12" className="px-0 mb-4">
              <FormGroup row className="align-items-center">
                <Label
                  for="exampleEmail"
                  sm={2}
                  md={2}
                  lg={2}
                  align="right"
                  className=" pe-md-3 ps-0 pe-0 addnewitem-typo textarea-label"
                >
                  Description
                </Label>
                <Col sm={9} md={10} lg={10} className="textarea-ip-con ps-3 ps-md-0">
                  <Input
                    disabled={isReadOnly}
                    type="textarea"
                    name="description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    value={formik.values.description}
                    id="exampleEmail"
                    className="textarea-max-width"
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <span className="error fs-10">
                      {formik.errors.description}
                    </span>
                  ) : null}
                </Col>
              </FormGroup>
            </Col>
            <Col sm="12" md="12" lg="12" className="px-0">
              <FormGroup row className="stock-status">
                <Label
                  for="exampleEmail"
                  sm={2}
                  align="right"
                  className=" ps-3 ps-md-0 pe-2 pe-md-3 ws-nowrap stock-status-label addnewitem-typo"
                >
                  Stock Status
                </Label>
                <ItemThresholdSection isReadOnly={isReadOnly} formik={formik} />
              </FormGroup>
            </Col>
          </Row>
        </div>
        {!isReadOnly && (<div className="text-center my-4 d-flex justify-content-center align-items-center">
          <Button
            type="submit"
            isLoading={isLoading}
            size="lg"
            className="gt-btn-grad-primary btn-height me-2 btn-alignment"
            title={<div>{footerBtnType}</div>}
          />
        </div>)}
      </Form>
    </>
  )
}

export default AddNewItemForm

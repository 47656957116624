import React, { useEffect, useState } from "react"
import {
  Switch,
  Route,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

import WarehouseInventory from "./InventoryMang-WarehouseInventory"
import DepartmentInventory from "./InventoryMang-DepartmentInventory"
import ItemsInventory from "./InventoryMang-ItemsInventory"
import { BackButtonTableTop as GoBack } from "components/BackButtonTableTop"
import { TableHeaderButtons as GroupedButtons } from "components/TableHeaderButtons"
import { Button } from "components/Button"
import { APP_ROUTES } from "helpers/routeHelpers"

const InventoryManagement = ({}) => {
  let history = useHistory()
  let location = useLocation()

  const [isActive, setIsActive] = useState({
    departmentInventory: false,
    warehouseInventory: false,
    itemsInventory: false,
  })
  useEffect(() => {
    setIsActive({
      departmentInventory: location.pathname == APP_ROUTES.INVENTORY_DEPARTMENT,
      warehouseInventory: location.pathname == APP_ROUTES.INVENTORY_WAREHOUSE,
      itemsInventory: location.pathname == APP_ROUTES.INVENTORY_ITEMS,
    })

    console.log(isActive)
  }, [location])

  const handleDepartmentInventory = () => {
    history.push(APP_ROUTES.INVENTORY_DEPARTMENT)
  }
  const handleWarehouseInventory = () => {
    history.push(APP_ROUTES.INVENTORY_WAREHOUSE)
  }
  const handleItemsInventory = () => {
    history.push(APP_ROUTES.INVENTORY_ITEMS)
  }

  const mainBtnsConfig = [
    {
      title: "Warehouse Inventory",
      onClick: handleWarehouseInventory,
      classesButton: "ms-0 ms-md-2",
      identifier: "warehouseInventory",
    },
    {
      title: "Departments Inventory",
      onClick: handleDepartmentInventory,
      classesButton: "ms-2",
      identifier: "departmentInventory",
    },
    {
      title: "Item",
      onClick: handleItemsInventory,
      classesButton: "ms-2",
      identifier: "itemsInventory",
    },
  ]

  const routesConfig = [
    {
      name: "Warehouse Inventory",
      isExact: false,
      component: WarehouseInventory,
      path: APP_ROUTES.INVENTORY_WAREHOUSE,
    },
    {
      name: "Departments Inventory",
      isExact: false,
      component: DepartmentInventory,
      path: APP_ROUTES.INVENTORY_DEPARTMENT,
    },
    {
      name: "Item",
      isExact: false,
      component: ItemsInventory,
      path: APP_ROUTES.INVENTORY_ITEMS,
    },
  ]

  return (
    <div className="gt-business-accounts">
      <Container fluid>
        <Row>
          <Col 
          // className="col-12 "
          className="col-12 px-lg-3 px-2"
          >
            <div 
            // className="d-flex align-items-center mb-4 mb-4 mt-lg-0 mt-3"
            className="d-flex align-items-md-center mb-4 mb-4 mt-lg-0 mt-3 wh-inventory-management flex-column flex-md-row align-items-start"
            >
              <GoBack />
              <div>
              <GroupedButtons
                data={mainBtnsConfig}
                classes={" im-header-btns btn-alignment"}
                isActive={isActive}
              />
              </div>
            </div>

            <Switch>
              {routesConfig.map(({ name, isExact, path, component }, idx) => (
                <Route
                  key={idx}
                  name={name}
                  exact={isExact}
                  path={path}
                  component={component}
                />
              ))}
              <Redirect to={APP_ROUTES.INVENTORY_WAREHOUSE} />
            </Switch>

            <div className="text-center d-flex justify-content-center align-items-center">
              <Button
                size="lg"
                className="gt-btn-grad-primary table-bottom-btn d-flex justify-content-center align-items-center ln-height-1 btn-alignment"
                title="Back to Main"
                onClick={history.goBack}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default InventoryManagement

import React, { useState } from "react"
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap"
import classnames from "classnames"

import { TableTopHeading } from "components/TableTopHeading"
import WHInventoryManagementWI from "assets/images/warehouse/inventory-management/wh-inventory-management-icon.png"
import { getAppName } from "utils/commonUtils"
import ItemCategoryListing from "./ItemCategoryListing"
import ItemsListing from "./ItemsListing"

const ItemsInventory = ({}) => {
  const [activeTabSecond, setActiveTabSecond] = useState("ic1")

  const toggle = tab => {
    if (activeTabSecond !== tab) {
      setActiveTabSecond(tab)
    }
  }

  let tabsConfig = [
    {
      title: "Items",
      identifier: "ic1",
      content: <ItemsListing />,
    },
    {
      title: "Categories",
      identifier: "ic3",
      content: <ItemCategoryListing />,
    },
  ]
  return (
    <>
      <div className={"warehouse-nav-tabs"}>
        <TableTopHeading
          title={`Warehouse Inventory (${getAppName()})`}
          iconImage={WHInventoryManagementWI}
          iconClass={"inventory-req-icon"}
          className="mb-3"
        />

        <>
          <Nav tabs className="border-0">
            {tabsConfig &&
              tabsConfig.map(el => {
                return (
                  <NavItem key={el.identifier}>
                    <NavLink
                      className={classnames({
                        active: activeTabSecond === el.identifier,
                      })}
                      onClick={() => {
                        toggle(el.identifier)
                      }}
                    >
                      {el.title}
                    </NavLink>
                  </NavItem>
                )
              })}
          </Nav>
          <TabContent activeTab={activeTabSecond}>
            {tabsConfig &&
              tabsConfig.map(el => {
                return (
                  <TabPane tabId={el.identifier} key={el.identifier}>
                    <Row>
                      <Col sm="12">
                        {activeTabSecond == el.identifier && el.content}
                      </Col>
                    </Row>
                  </TabPane>
                )
              })}
          </TabContent>
        </>
      </div>
    </>
  )
}

export default ItemsInventory

import React from "react"

import { DataTable } from "components/DataTable"
import { TextInput } from "components/TextInput"
import { AutoComplete } from "components/AutoComplete"
import { Button } from "components/Button"
import { makeOptionsDisabled } from "../../../utils/commonUtils"

import DeleteIcon from "../../../assets/images/inventory/delete-icon.png"
import { AutoCompleteStyling } from "../AutoCompleteStyling"

const COLUMNS = {
  ITEM_NAME: "Item Name",
  SKU_NO: "SKU No.",
  REQUIRED_QUANTITY: "Required Quantity",
}

const errorsData = ["", "Field Required", "value should be > 0"]

const CreateOrderWarehouseModal = ({
  title,
  footerBtnType,
  deleteOption = true,
  createOrderListing,
  handleSearch,
  setCreateOrderListing,
  modalDataList,
  handleModalInputPriceValueChange,
  handleModalListDelete,
  handleOnSelectOptionModalAutocomplete,
  handleCreateOrder,
  isAddingRequest,
  modalItemSelected,
}) => {

  const handleModalListDeleteItem = (id, sku) => {
    createOrderListing?.data.map(el => {
      if(el.skuNo === sku){
        el.isDisabled = false
      }
    })
    handleModalListDelete(id)
  }
  return (
    <>
      <h3 className="text-center inv-req-popup-heading">{title}</h3>
      <div className="pt-3 pb-4 add-new-req-select-con">
        <span className="font-size-13">Add Items : </span>
        <AutoComplete
          handleBlur={() =>
            setCreateOrderListing(prev => {
              return {
                ...prev,
                page: 1,
                Q: "",
              }
            })
          }
          onChange={handleOnSelectOptionModalAutocomplete}
          onInputChange={val => handleSearch(val, setCreateOrderListing)}
          placeholder="Search Item"
          classNamePrefix="add-new-req"
          customStyles={AutoCompleteStyling}
          options={makeOptionsDisabled(modalDataList, createOrderListing)}
          value={createOrderListing?.data.filter(
            el => el.label === modalItemSelected
          )}
          isLoading={createOrderListing?.fetching}
          hideSelectedOptions
        />
      </div>
      <DataTable
        data={modalDataList}
        className="view-detail-inv-req-table add-req-inv-con height-scroll-table"
        tableClasses="create-order-im"
        config={[ 
          {
            title: COLUMNS.SKU_NO,
            render: data => {
              return data?.skuNo
            },
          },
          {
            title: COLUMNS.ITEM_NAME,
            render: data => {
              return data?.itemName
            },
          },
          {
            title: COLUMNS.REQUIRED_QUANTITY,
            className:"create-order-lastitem",
            render: data => {
              return (
                <>
                  <div >
                    <TextInput
                      onChange={e =>
                        handleModalInputPriceValueChange(e, data.id)
                      }
                      className="view-detail-inv-req-p-ip-con me-4 me-md-5"
                      placeholder="0"
                      labelClass="d-none"
                      type="number"
                      min={1}
                      inputClass="view-detail-inv-req-p-ip"
                      value={data.requiredQuantity}
                      name="price"
                    />
                    {deleteOption && (
                      <img
                        onClick={e => handleModalListDeleteItem(data, data.skuNo)}
                        className="view-detail-modal-delete"
                        src={DeleteIcon}
                      />
                    )}
                  </div>
                  <span className="text-danger d-block error-msg-below-input error-message-aligning">
                    {errorsData[data?.quantityError]}
                  </span>
                </>
              )
            },
          },
        ]}
      />
      <div className="text-center my-4 d-flex justify-content-center align-items-center">
        <Button
          isLoading={isAddingRequest}
          size="lg"
          className="gt-btn-grad-primary table-bottom-btn me-2 btn-alignment"
          title={<div>{footerBtnType}</div>}
          onClick={handleCreateOrder}
          disabled={!modalDataList.length}
        />
      </div>
    </>
  )
}

export default CreateOrderWarehouseModal

import React from "react"
import { Button } from "components/Button"

import {
  Row,
  Col,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap"
import DeleteIcon from "../../../assets/images/inventory/delete-icon.png"
import { AutoCompleteStyling } from "../../Common/AutoCompleteStyling"
import Icon from '../../../assets/images/content-wrap-bg.png'
import SampleFile from '../../../assets/files/sample-file.csv'
import DownloadIcon from '../../../assets/images/icons/download-csv.png'

const MultiAddInventoryModal = ({
  title,
  addInventoryModalOpen,
  handleInvFileUploadModalOpen
}) => {
  const [data, setData] = React.useState({
    itemname: "",
  })

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  return (
    <>
      <Form>
        <h3 className="text-center inv-req-popup-heading mb-0">{title}</h3>
        {/* <h5 className="text-center inv-req-popup-heading wh-im-dpgi-addnewitem">
          {subTitle}
        </h5> */}
        <div className="text-center mt-3 mb-3 d-flex justify-content-center align-items-center add-inv-multi">
          <Button
            // isLoading={isAddingRequest}
            size="lg"
            className="gt-btn-grad-primary btn-height btn-alignment"
            title="Add Inventory"
            onClick={addInventoryModalOpen}
          />
        </div>
        <div className="text-center mt-0 mb-3 d-flex justify-content-center align-items-center add-inv-multi">
          <Button
            // isLoading={isAddingRequest}
            size="lg"
            className="gt-btn-grad-primary btn-height btn-alignment "
            title="Add Bulk Inventory"
            onClick={handleInvFileUploadModalOpen}
          />
        </div>
        <div className="inv-sample-download mt-0 mb-3">          
          <a href={SampleFile}><img src={DownloadIcon} alt="download icon" className="download-img-icon"/>Download Sample File</a>
        </div>
      </Form>
    </>
  )
}

export default MultiAddInventoryModal

var Config = {
  LIMIT: "10",
  PERSIST_SECRET_KEY: "svfsvfvfvfv",
  ENVIRONMENT: "STAGING",
  ENVIRONMENTS: {
    LOCAL: {
      API_URL: "https://demo-api.casita.kodefuse.com/v1",
    },
    DEVELOPMENT: {
      API_URL: "https://demo-api.casita.kodefuse.com/v1",
    },
    STAGING: {
      API_URL: "https://demo-api.casita.kodefuse.com/v1",
    },
    PRODUCTION: {
      API_URL: "https://demo-api.casita.kodefuse.com/v1",
    },
  },
}

Config.env = () => {
  return Config.ENVIRONMENTS[Config.ENVIRONMENT]
}

export default Config
import { store } from "../store"
import { AuthCode } from "../constants"
import { SIGN_OUT } from "../store/actions"
import { toaster } from "../utils/toasterUtil"

export const handleFetchError = async (res, showToaster = false) => {
  if (
    res.status >= AuthCode.BAD_REQUEST &&
    res.status < AuthCode.CONNECTION_TIMED_OUT
  ) {
    const response = await res.clone().json()
    let errRes = {
      ...response,
      Message: response.Message,
      Status: res.status,
    }
    if (res.status === AuthCode.UNAUTHORIZED) {
      store.dispatch({ type: SIGN_OUT })
      localStorage.clear()
    }
    showToaster && toaster({ title: response.message, type: "danger" })
    throw errRes
  }
  return res.json()
}

import { combineReducers } from "redux"
import { SIGN_OUT } from "../actions"

import Layout from "../layout/reducer"
import authReducer from "./authReducer"
import root from "./rootReducer"
import deviceIdReducer from "./deviceIdReducer"
import checkBoxUpdateModalItems from "./checkBoxUpdateModalItems"
import notificationsReducer from "./notificationsReducer"

const appReducer = combineReducers({
  Layout,
  root,
  userAuth: authReducer,
  deviceId: deviceIdReducer,
  checkBox: checkBoxUpdateModalItems,
  notifications: notificationsReducer,
})

const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT) {
    const { Layout, deviceId } = state
    state = { Layout, deviceId }
  }

  return appReducer(state, action)
}

export default rootReducer

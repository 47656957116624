import React from "react"
import { jsPDF } from "jspdf"

import dowloadPdfIcon from "assets/images/icons/download-pdf-icon.svg"
import { DataTable } from "components/DataTable"
import { Button } from "components/Button"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import { InventoryReqItemsStatus } from "constants"
import useGetPdfReport from "hooks/useGetPdfReport"

const COLUMNS = {
  PO_No: "PO No",
  VENDOR_NAME: "Vendor Name",
  ORDER_DATE: "Order Date",
  ITEM_NAME: "Item Name",
  SUBTITUTED_ITEM_NAME: "Subsitute Name",
  SKU_NO: "SKU No.",
  ORDERED_QUANTITY: "Ordered Quantity",
  STATUS: "Status",
  STATUS_UPDATE: "Status Update",
  QUANTITY_DELIVERED: "Quantity Delivered",
  ACTION: "Action",
  INVOICE: "Invoice",
  UNIT_PRICE: "Unit Price ($)",
  DATE_OF_RECEIVED: "Received At",
}
const PDF_TYPE = "Warehouse"

const A4_PAPER_DIMENSIONS = {
  width: 210,
  height: 297,
}

const filterArrElementNull = data => {
  let tempData = [...data?.invoice]
  let fitleredData = tempData.filter(el => el !== null)
  let output = fitleredData.length === 0 || fitleredData === null ? true : false
  return output
}
// Creates a PDF document containing all the uploaded images.
const generatePdfFromImages = images => {
  console.log("images", images.length)
  // return false;
  // Default export is A4 paper, portrait, using millimeters for units.
  if (images.length > 0) {
    const doc = new jsPDF()

    // We let the images add all pages,
    // therefore the first default page can be removed.
    doc.deletePage(1)

    images.forEach(image => {
      // const imageDimensions = imageDimensionsOnA4({
      //   width: image.width,
      //   height: image.height,
      // });
      doc.addPage()
      doc.addImage(
        image.path,
        image.extension === "png" ? "PNG" : "JPEG",
        // Images are vertically and horizontally centered on the page.
        (A4_PAPER_DIMENSIONS.width - 150) / 2,
        (A4_PAPER_DIMENSIONS.height - 200) / 2,
        150,
        200
      )
    })
    doc.save("invoices")
  }
  // Creates a PDF and opens it in a new browser tab.
  // const pdfURL = doc.output("invoices");
  // window.open(pdfURL , "_blank");
}

const ViewOrderItemsModal = ({ modalCloseHandler, data = {} }) => {
  const handleInvoicesDownload = imagesData => {
    let fitleredData = imagesData?.invoice.filter(el => el !== null)
    if (fitleredData == null || fitleredData.length == 0) return false
    generatePdfFromImages(fitleredData)
  }

  const { fetchingReport, handleGetPdfReport, reportData } = useGetPdfReport()

  return (
    <>
      <h3 className="text-center inv-req-popup-heading">
        Order Id : {data.orderNo || "-"}
      </h3>
      <DataTable
        data={data?.orders}
        className="view-detail-inv-req-table"
        tableClasses="detail-view-table"
        config={[
          {
            title: COLUMNS.PO_No,
            render: data => {
              return data?.poNo.join(", ") || "-"
            },
          },
          {
            title: COLUMNS.ITEM_NAME,
            render: data => {
              return data?.itemName || "-"
            },
          },
          {
            title: COLUMNS.SUBTITUTED_ITEM_NAME,
            render: data => {
              return data?.substitute?.itemName || "-"
            },
          },
          {
            title: COLUMNS.SKU_NO,
            render: data => {
              return data?.skuNo || "-"
            },
          },
          {
            title: COLUMNS.VENDOR_NAME,
            render: data => {
              return data?.vendorName.join(", ") || "-"
            },
          },
          {
            title: COLUMNS.ORDERED_QUANTITY,
            render: data => {
              return data?.quantity || "-"
            },
          },

          {
            title: COLUMNS.UNIT_PRICE,
            render: data => {
              return data?.unitPrice.join(", ") || "-"
            },
          },
          {
            title: COLUMNS.DATE_OF_RECEIVED,
            render: data => {
              return data.createdAt
                ? convertDateTime({
                    date: data.createdAt,
                    customFormat: dateTimeFormat.casitaCutomDateFormat,
                    dateOnly: true,
                  })
                : "-"
            },
          },
          {
            title: COLUMNS.STATUS,
            render: data => {
              return (
                <div className="d-flex align-items-center justify-content-evenly">
                  <div
                    className={`inv-req-popup-status ${InventoryReqItemsStatus.getLabelClass(
                      data.status
                    )}`}
                  >
                    {InventoryReqItemsStatus.getDisplayTextKey(data.status)}
                  </div>
                </div>
              )
            },
          },
          {
            title: COLUMNS.QUANTITY_DELIVERED,
            render: data => {
              return data?.quantitySupplied || "-"
            },
          },
          {
            title: COLUMNS.INVOICE,
            render: data => {
              return (
                <img
                  onClick={e => handleInvoicesDownload(data)}
                  src={dowloadPdfIcon}
                  className={`${
                    filterArrElementNull(data) && "pdfdownload-gray"
                  } pdfdownload-icon`}
                />
              )
            },
          },
        ]}
      />
      <div className="text-center my-4">
        <Button
          size="lg"
          className="gt-btn-grad-primary table-bottom-btn mx-2 btn-alignment"
          title={<div>Done</div>}
          onClick={() => modalCloseHandler(false)}
        />
        <Button
          isLoading={fetchingReport}
          size="lg"
          className="gt-btn-grad-primary table-bottom-btn mx-2 px-5 btn-alignment"
          title={<div>Print Order</div>}
          onClick={() => handleGetPdfReport(data?.orderNo, PDF_TYPE)}
        />
      </div>
    </>
  )
}

export default ViewOrderItemsModal

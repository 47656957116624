import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Col, Container, Row, Card, CardBody } from "reactstrap"
import { toast } from "react-hot-toast"

import customStyles from "../InventoryRequests/HeaderStatusStyling"
import ScrapIcon from "assets/images/icons/scrap-return-nav-icon.png"
import ActionEyeIcon from "assets/images/inventory-request/action-eye-icon.png"
import { ScrapReturnForm } from "pages/Common/ScrapReturn"
import { BackButtonTableTop } from "components/BackButtonTableTop"
import { TableTopHeading } from "components/TableTopHeading"
import { PageLimit } from "components/PageLimit"
import { DataTable } from "components/DataTable"
import { Pagination } from "components/Pagination"
import { Button } from "components/Button"
import { Modal } from "components/Modal"
import { AutoComplete } from "components/AutoComplete"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"
import { getAppName, getSelectBoxOptionsFromConstants } from "utils/commonUtils"
import { ScrapReturnType } from "constants"
import {
  GetScrapReturnListing,
  GetScrapReturnRequestById,
} from "api/api.service"

const COLUMNS = {
  SKU_NO: "SKU No.",
  ITEM_NAME: "Item Name",
  TYPE: "Type",
  QUANTITY: "Quantity",
  ISSUES: "Issues",
  DATE_OF_REQUEST: "Requested Date",
  ACTION: "Action",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"
const SCRAP_REQUEST = "DEPARTMENT"

const ScrapReturnListing = ({}) => {
  let typeOptions = getSelectBoxOptionsFromConstants(ScrapReturnType)

  const history = useHistory()
  const [typeFilter, setTypeFilter] = useState(null)
  const [toggleModal, setToggleModal] = useState(false)
  const [isFetchingRequest, setIsFetchingRequest] = useState(false)
  const [selectedListItem, setSelectedListItem] = useState("")

  const {
    page,
    data,
    total,
    pageSize,
    isFetching,
    handlePageClick,
    onChangePageSize,
  } = useLocalPaginatedRequest({
    requestFn: GetScrapReturnListing,
    params: {
      ...(typeFilter?.value && { requestType: typeFilter?.value }),
    },
    deps: [typeFilter],
    dataKey: DATA_KEY,
    totalKey: TOTAL_KEY,
  })

  const handleViewScrapDetails = async (scrapId = null) => {
    if (!scrapId) return

    let paramsObj = {
      type: SCRAP_REQUEST,
    }

    setToggleModal(true)

    try {
      setIsFetchingRequest(true)
      let res = await GetScrapReturnRequestById(scrapId, paramsObj)
      setSelectedListItem(res)
    } catch (err) {
      toast.error(err.message)
    } finally {
      setIsFetchingRequest(false)
    }
  }

  const handleSelectOption = option => {
    setTypeFilter(option)
  }

  const onCloseModal = () => {
    setToggleModal(false)
    setSelectedListItem("")
  }

  return (
    <>
      <div className="gt-business-accounts px-3 px-md-5">
        <Container fluid>
          <Row>
            <Col className="col-12 ">
              <div className="d-flex align-items-center mb-4">
                <BackButtonTableTop />
              </div>

              <TableTopHeading
                title={`Scrap / Return (${getAppName()})`}
                iconImage={ScrapIcon}
                iconClass="scrap-icon"
              />

              <Card className="mb-5">
                <CardBody className="p-2 p-md-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="fw-600 mb-0 table-main-heading">Requests</h5>
                    <div className="d-flex align-items-center justify-content-center flex-column flex-md-row ">
                      <div className="status-search-con">
                        <span className="status-label mx-2">Type : </span>
                        <AutoComplete
                          isClearable
                          value={typeFilter}
                          placeholder="Select status"
                          isSearchable={false}
                          customStyles={customStyles.InventoryHeader}
                          classNamePrefix="status-header-search"
                          options={typeOptions}
                          onChange={val => handleSelectOption(val)}
                        />
                      </div>
                    </div>
                  </div>
                  <PageLimit
                    pageSize={pageSize}
                    changePageSizeHandler={onChangePageSize}
                    rowLimit={pageSize}
                    typoClasses="page-limit-typo"
                    selectClasses="page-limit-select"
                  />

                  <DataTable
                    data={data}
                    loading={isFetching}
                    tableClasses="table-listing"
                    config={[
                      {
                        title: COLUMNS.SKU_NO,
                        render: data => {
                          return (
                            <>
                              <div
                                onClick={() => setInventoryDetailsModal(true)}
                              >
                                {data.skuNo || "-"}
                              </div>
                            </>
                          )
                        },
                      },
                      {
                        title: COLUMNS.ITEM_NAME,
                        render: data => {
                          return data?.itemName || "-"
                        },
                      },
                      {
                        title: COLUMNS.TYPE,
                        render: data => {
                          return data?.type || "-"
                        },
                      },

                      {
                        title: COLUMNS.QUANTITY,
                        render: data => {
                          return <>{data.quantity || "-"}</>
                        },
                      },
                      {
                        title: COLUMNS.ISSUES,
                        render: data => {
                          return data?.issue || "-"
                        },
                      },

                      {
                        title: COLUMNS.DATE_OF_REQUEST,
                        render: data => {
                          return (
                            convertDateTime({
                              date: data.createdAt,
                              customFormat:
                                dateTimeFormat.casitaCutomDateFormat,
                              dateOnly: true,
                            }) || "-"
                          )
                        },
                      },
                      {
                        className: "text-center",
                        title: COLUMNS.ACTION,
                        render: data => {
                          return (
                            <span
                              className="text-success pointer"
                              onClick={() => handleViewScrapDetails(data.id)}
                            >
                              <img src={ActionEyeIcon} />
                            </span>
                          )
                        },
                      },
                    ]}
                  />
                  <Pagination
                    currentPage={page}
                    totalData={total}
                    onPageClick={handlePageClick}
                    rowLimit={pageSize}
                  />
                </CardBody>
              </Card>
              <div className="text-center ">
                <Button
                  size="lg"
                  className="gt-btn-grad-primary table-bottom-btn btn-alignment mb-3"
                  title={<div>Back to Main</div>}
                  onClick={history.goBack}
                />
              </div>
            </Col>
          </Row>

          <Modal
            isOpen={toggleModal}
            handleModalToggling={() => setToggleModal(false)}
          >
            <ScrapReturnForm
              data={selectedListItem}
              closeModal={() => onCloseModal()}
            />
          </Modal>
        </Container>
      </div>
    </>
  )
}

export default ScrapReturnListing

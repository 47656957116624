import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card } from "reactstrap"
import { toast } from "react-hot-toast"

import { TableTopHeading } from "components/TableTopHeading"
import { SearchBox } from "components/SearchBox"
import InventoryRequestIcon from "assets/images/warehouse/inventory-request/wh-rm-header-icon.png"
import ActionEyeIcon from "assets/images/inventory-request/action-eye-icon.png"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { PageLimit } from "components/PageLimit"
import { AutoComplete } from "components/AutoComplete"
import PullbackRequestItems from "./PullbackRequestItems"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { InventoryReqStatus } from "constants"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import customStyles from "./HeaderStatusStyling"
import {
  GetPullbackRequestListing,
  GetDepartmentsListing,
  DepartmentFulfillmentByWarehouse,
} from "api/api.service"
import Config from "Config"

const COLUMNS = {
  REQUEST_NO: "Request No.",
  DATE_OF_REQUEST: "Requested Date",
  DEPARTMENT: "Department",
  STATUS: "Status",
  ACTION: "Actions",
}
const DATA_KEY = "data"
const TOTAL_KEY = "total"

const DepartmentRequestListing = () => {
  let controller = new AbortController()
  let signal = controller.signal
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const [statusFilters, setStatusFilters] = useState(null)
  const [departmentsListing, setDepartmentsListing] = useState({
    data: [],
    fetching: true,
    page: 1,
    isDataAvailable: true,
  })
  const [modalToggle, setModalToggle] = useState(false)
  const [departmentsFilter, setDepartmentsFilter] = useState(null)

  const {
    data,
    page,
    pageSize,
    total,
    isFetching,
    searchText,
    request,
    handleSearchChange,
    handlePageClick,
    onChangePageSize,
  } = useLocalPaginatedRequest({
    requestFn: GetPullbackRequestListing,
    params: {
      ...(statusFilters?.value && { requestStatus: statusFilters?.value }),
      ...(departmentsFilter?.length && {
        departmentId: departmentsFilter.map(item => item.value).join(),
      }),
    },
    deps: [statusFilters, departmentsFilter],
    dataKey: DATA_KEY,
    totalKey: TOTAL_KEY,
  })

  useEffect(() => {
    handleDepartmentsListing(departmentsListing.page)

    return () => {
      controller.abort()
    }
  }, [departmentsListing.Q])

  const handleSelectDepartments = option => {
    if (typeof option != "object" || !option) return
    // if (!Object.keys(option)) return
    setDepartmentsFilter(option)
  }
  const handleDepartmentSearch = (value = "", callback) => {
    if (!value) return

    callback(prev => {
      return {
        ...prev,
        data: [],
        page: 1,
        Q: value,
      }
    })
  }

  const handleDepartmentsListing = page => {
    setDepartmentsListing(prev => {
      return {
        ...prev,
        fetching: true,
      }
    })

    GetDepartmentsListing(
      {
        Limit: Config.LIMIT,
        ...(departmentsListing.Q && { Q: departmentsListing.Q }),
        Page: page,
      },
      signal
    )
      .then(res => {
        setDepartmentsListing(prev => ({
          ...prev,
          fetching: false,
          page: page + 1,
          isDataAvailable: res?.data?.length == Config.LIMIT,
          data: (() => {
            let test = res?.data?.map(item => ({
              ...item,
              value: item.id,
              label: item.departmentName,
            }))

            return [...test]
          })(),
        }))
      })
      .finally(() => {
        setDepartmentsListing(prev => {
          return {
            ...prev,
            fetching: false,
          }
        })
      })
  }

  const statusSelectOptions = [
    {
      label: "Open",
      value: "Open",
    },
    {
      label: "In Progress",
      value: "InProgress",
    },
    {
      label: "Completed",
      value: "Completed",
    },
  ]

  const handleModalToggle = data => {
    setSelectedData(data || {})
    setModalToggle(prev => !prev)
  }
  const handleSelectStatusOption = option => {
    setStatusFilters(option)
  }

  const handleOnSubmit = async payload => {
    let data = { ...payload }

    try {
      setIsSubmitting(true)
      let res = await DepartmentFulfillmentByWarehouse(data)
      handleModalToggle()
      toast.success("Request has been fulfilled")
      request()
    } catch (err) {
      toast.err(err.message)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      {/* <div className="gt-business-accounts">
        <Container fluid>
          <Row>
            <Col className="col-12 "> */}
              <div className="d-flex align-items-md-center mb-4 mt-lg-0 mt-3 top-heading-con flex-column flex-md-row align-items-start">
                <TableTopHeading
                  title={"Inventory Requests"}
                  className="top-heading-typo me-0 me-lg-4"
                  iconImage={InventoryRequestIcon}
                  iconClass="inventory-req-icon"
                />
                <div
                  className="d-flex align-items-center justify-content-start justify-content-lg-start justify-content-md-end mt-3 mt-md-0"
                  style={{ width: "400px" }}
                >
                  <div className=" status-search-con wh-ir-header-select-con me-3">
                    <AutoComplete
                      // isClearable

                      isMulti
                      placeholder="Departments"
                      value={departmentsFilter}
                      customStyles={customStyles.IMRequests}
                      classNamePrefix="status-header-search"
                      handleBlur={() =>
                        setDepartmentsListing(prev => {
                          return {
                            ...prev,
                            page: 1,
                            Q: "",
                          }
                        })
                      }
                      onInputChange={val =>
                        handleDepartmentSearch(val, setDepartmentsListing)
                      }
                      options={departmentsListing.data}
                      onChange={val => handleSelectDepartments(val)}
                      closeMenuOnSelect={true}
                      isLoading={departmentsListing?.fetching}
                    />
                  </div>
                  <div className="status-search-con wh-ir-header-select-con me-3 ml-2">
                    <AutoComplete
                      value={statusFilters}
                      isClearable
                      onChange={val => handleSelectStatusOption(val)}
                      isSearchable={false}
                      placeholder="Select status"
                      customStyles={customStyles.IMRequests}
                      classNamePrefix="status-header-search"
                      options={statusSelectOptions}
                    />
                  </div>
                </div>
              </div>

              <Card className="mb-5">
                <CardBody className="p-2 p-md-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="fw-600 mb-0 table-main-heading">Requests</h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-between  ">
                    <PageLimit
                      pageSize={pageSize}
                      changePageSizeHandler={onChangePageSize}
                      rowLimit={pageSize}
                      typoClasses="page-limit-typo"
                      selectClasses="page-limit-select"
                    />
                    <div>
                      <SearchBox
                        searchText={searchText}
                        onChange={handleSearchChange}
                        // labelClass="wh-im-search-label"
                        inputClasses="table-main-search-input wh-im-search-input"
                        // placeholder='Search...'
                      />
                    </div>
                  </div>
                  <DataTable
                    data={data}
                    loading={isFetching}
                    tableClasses="inv-req-pullback-table"
                    config={[
                      {
                        title: COLUMNS.REQUEST_NO,
                        render: data => data.id,
                      },
                      {
                        title: COLUMNS.DATE_OF_REQUEST,
                        render: data =>
                          convertDateTime({
                            date: data.createdAt,
                            customFormat: dateTimeFormat.casitaCutomDateFormat,
                            dateOnly: true,
                          }),
                      },
                      {
                        title: COLUMNS.DEPARTMENT,
                        render: data => data?.departmentName,
                      },
                      {
                        title: COLUMNS.STATUS,
                        render: data => {
                          return (
                            <div
                              className={`inv-req-popup-status ${InventoryReqStatus.getLabelClass(
                                data.status
                              )}`}
                            >
                              {InventoryReqStatus.getDisplayTextKey(
                                data.status
                              )}
                            </div>
                          )
                        },
                      },
                      {
                        className: "text-center",
                        title: COLUMNS.ACTION,
                        render: data => {
                          return (
                            <span
                              className="text-success pointer"
                              onClick={() => handleModalToggle(data)}
                            >
                              <img src={ActionEyeIcon} />
                            </span>
                          )
                        },
                      },
                    ]}
                  />
                  <Pagination
                    currentPage={page}
                    totalData={total}
                    onPageClick={handlePageClick}
                    rowLimit={pageSize}
                  />
                </CardBody>
              </Card>
            {/* </Col>
          </Row>
        </Container>
      </div> */}

      <Modal
        styles={{ minHeight: "60vh" }}
        isOpen={modalToggle}
        handleModalToggling={handleModalToggle}
        bodyClassName="p-0"
        customButton={true}
        hideModalHeaderSeparator={true}
        headerClasses="pb-0"
        className="inv-req-popup-viewdetails"
        sizeClasses="5"
      >
        <PullbackRequestItems
          modalCloseHandler={handleModalToggle}
          data={selectedData}
        />
      </Modal>
    </>
  )
}

export default DepartmentRequestListing

import { injectBaseConstantMethods } from "./BaseConstants"

const UserTypes = {
  USER: "USER",
  WAREHOUSE: "WAREHOUSE",
  DEPARTMENT: "DEPARTMENT",
  ADMIN: "ADMIN",
  FINANCE: "FINANCE",
  EXECUTIVE: "EXECUTIVE",
}

const displayTextKeys = {
  [UserTypes.ADMIN]: "Admin",
  [UserTypes.DEPARTMENT]: "Department",
  [UserTypes.FINANCE]: "Finance",
  [UserTypes.EXECUTIVE]: "Executive",
  [UserTypes.WAREHOUSE]: "Warehouse",
  [UserTypes.USER]: "User",
}

const labelClass = {}

export default injectBaseConstantMethods(UserTypes, displayTextKeys, labelClass)

import React, { useState } from "react"
import { CardBody, Container, Row, Col, Card } from "reactstrap"
// import { BreadCrumb } from "../../components/BreadCrumb"

import { AutoComplete } from "components/AutoComplete"
import { TableTopHeading } from "components/TableTopHeading"
import { BackButtonTableTop } from "components/BackButtonTableTop"
import InventoryRequestIcon from "../../../assets/images/inventory-request/InventoryRequestIcon.png"
import ActionEyeIcon from "../../../assets/images/inventory-request/action-eye-icon.png"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { TableHeaderButtons } from "components/TableHeaderButtons"
import { PageLimit } from "components/PageLimit"

import customStyles from "./HeaderStatusStyling"
import ViewDetailsInventoryRequest from "./ViewDetailsDepartmentRequest"
import ViewDetailsPullBackRequest from "./ViewDetailsPullBackRequest"

import { Button } from "components/Button"
import { Modal } from "components/Modal"
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers"
import { TextInput } from "components/TextInput"

import { useHistory } from "react-router-dom"

import { GetDepartmentInventoryRequestListing } from "../../../api/api.service"
import { updateObjCheckBox } from "../../../utils/commonUtils"

//actions
import { updateModalItems } from "../../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"

// importing data from file
import { OrderLists } from "./inventoryRequestData"

// importing status
import { InventoryReqStatus } from "constants"
import InventoryRequestDepartmentListing from "./InventoryReq-DepartmentListing"
import InventoryRequestPullbackListing from "./InventoryReq-PullbackListing"

const COLUMNS = {
  REQUEST_NO: "Request No.",
  DATE_OR_REQUEST: "Requested Date",
  STATUS: "Status",
  ACTION: "Action",
}

const dataKey = ""
const totalKey = ""

const InventoryRequestListing = () => {
  const history = useHistory()
  const [selectedBusiness, setSelectedBusiness] = useState(null)
  const [filterModal, setFilterModal] = useState(false)
  const [dataRefetch, setDataRefetch] = useState(false)
  const [filters, setFilters] = useState(null)
  const [departmentRequestModal, setDepartmentRequestModal] = useState(false)
  const [pullBackRequestModal, setPullbackRequestModal] = useState(false)
  const [isActive, setIsActive] = useState({
    departmentRequest: true,
    pullBackRequest: false,
  })

  const onClickDepartmentRequest = () => {
    setIsActive({
      ...isActive,
      departmentRequest: true,
      pullBackRequest: false,
    })
  }
  const onClickPullBackRequest = () => {
    setIsActive({
      ...isActive,
      departmentRequest: false,
      pullBackRequest: true,
    })
  }

  const headerOuterButtonsData = [
    {
      title: "Department Requests",
      onClick: onClickDepartmentRequest,
      classesButton: "ms-0 ms-md-2",
      identifier: "departmentRequest",
    },
    {
      title: "Pull Back Request",
      onClick: onClickPullBackRequest,
      classesButton: "ms-2",
      identifier: "pullBackRequest",
    },
  ]

  return (
    <React.Fragment>
      <div className="gt-business-accounts px-3 px-md-5">
        <Container fluid>
          <Row>
            <Col className="col-12 ">
              <div className="d-flex align-items-center mb-4 mb-4 mt-lg-0 mt-3 inv-req-top-con">
                <BackButtonTableTop className="mb-0 "  backarrowCustom="ir-top-header"/>
                <TableHeaderButtons
                  data={headerOuterButtonsData}
                  classes={" px-4 btn-alignment "}
                  isActive={isActive}
                />
              </div>

              {isActive.departmentRequest ? (
                <InventoryRequestDepartmentListing />
              ) : (
                <InventoryRequestPullbackListing />
              )}

              <div className="text-center ">
                <Button
                  size="lg"
                  className="gt-btn-grad-primary table-bottom-btn btn-alignment mb-3"
                  title={<div>Back to Main</div>}
                  onClick={history.goBack}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InventoryRequestListing
